var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useListLabelTypesVersionsQuery } from '../../../../api/api';
import { useSetupLabelMutation } from '../../../../api/labelApi';
import { IRTargetList } from '../../../../components/atoms/IRTargetList';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
import { getPricerLabelType } from '../../label';
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
export function SetupLabelDialog(_a) {
    var _this = this;
    var _b;
    var label = _a.label, open = _a.open, onClose = _a.onClose, defaultTarget = _a.target;
    var _c = useMessageHandler(), addError = _c.addError, addApiError = _c.addApiError;
    var add = usePendingContext().add;
    var setupLabel = useSetupLabelMutation()[0];
    var _d = React.useState([]), hardwareRevisions = _d[0], setHardwareRevisions = _d[1];
    var _e = React.useState('' + (((_b = label === null || label === void 0 ? void 0 : label.hardwareRevision) === null || _b === void 0 ? void 0 : _b.value) || 1)), hardwareRevision = _e[0], setHardwareRevision = _e[1];
    var _f = React.useState(defaultTarget === 'auto' ? '' : defaultTarget), target = _f[0], setTarget = _f[1];
    var plType = '' + getPricerLabelType(label.barcode);
    var _g = useListLabelTypesVersionsQuery({ plType: plType, size: 100 }), versions = _g.data, isError = _g.isError, error = _g.error;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch label-types');
        }
    }, [isError, addError, error]);
    useEffect(function () {
        if (versions === null || versions === void 0 ? void 0 : versions.content) {
            var hardwareRevisions_1 = versions.content
                .map(function (v) { return v.hardwareRevision; })
                .filter(onlyUnique)
                .sort(function (a, b) { return a - b; })
                .map(function (v) { return '' + v; });
            setHardwareRevisions(hardwareRevisions_1);
        }
    }, [versions]);
    var onSetup = function () { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (label === undefined) {
                        addError("No label selected");
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, setupLabel({
                            barcode: label.barcode,
                            target: target,
                            hardwareRevision: +hardwareRevision
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'SETUP', label.barcode);
                    onClose();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to setup ".concat(label === null || label === void 0 ? void 0 : label.barcode), e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsxs(Dialog, { open: open, children: [_jsx(DialogTitle, { children: "Reset label before you click Setup below." }), _jsx(DialogContent, { children: _jsxs(Stack, { spacing: 3, sx: { minWidth: 120 }, children: [_jsx(IRTargetList, { removeAuto: true, label: label, accessPoint: target, setAccessPoint: setTarget }), _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "hardware", children: "Hardware Revision" }), _jsx(Select, { labelId: "hardware", id: "demo-simple-select", value: hardwareRevision, label: "Hardware Revision", onChange: function (e) { return setHardwareRevision(e.target.value); }, children: hardwareRevisions.map(function (h) { return (_jsx(MenuItem, { value: h, children: h }, h)); }) })] })] }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return onClose(); }, children: "Cancel" }), _jsx(PricerButton, { type: "dangerous", onClick: onSetup, children: "Setup" })] })] }) }));
}
