import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { relativeTime } from '../../../../common/helpers';
export function UntrustedAccessPointListItem(_a) {
    var accessPoint = _a.accessPoint, onClick = _a.onClick;
    var icon = accessPoint.online ? (_jsx(WifiTetheringIcon, { sx: { color: '#054816' } })) : (_jsx(WifiTetheringOffIcon, { sx: { color: '#e53800' } }));
    var hasSecret = accessPoint.hasSecret ? 'has' : 'does not have';
    var trusted = accessPoint.trusted ? 'Trusted' : 'Untrusted';
    return (_jsx(Card, { sx: { minWidth: { sm: 1 / 2, md: 0.9 } }, children: _jsxs(CardActionArea, { onClick: function () { return onClick(accessPoint); }, children: [_jsx(CardHeader, { avatar: icon, title: accessPoint.name, subheader: relativeTime(accessPoint.seen) }), _jsx(CardContent, { children: _jsxs(Typography, { component: "div", variant: "body1", children: [trusted, " base station was last connected from ", accessPoint.ip, " and", ' ', hasSecret, " a configured secret."] }) })] }) }));
}
