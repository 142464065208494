var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';
export function CommandSelector(_a) {
    var title = _a.title, _b = _a.disabled, disabled = _b === void 0 ? false : _b, commands = _a.commands, command = _a.command, onChange = _a.onChange;
    var formatHex = function (val) { return val.toString(16).padStart(2, '0'); };
    var options = Object.keys(commands).map(function (key) { return (__assign(__assign({}, commands[+key]), { id: +key })); });
    var current = options.find(function (option) { return option.id === command; });
    return (_jsx(Autocomplete, { fullWidth: true, disabled: disabled, freeSolo: true, clearOnBlur: true, value: formatHex(command), getOptionLabel: function (option) {
            if (typeof option == 'string') {
                return option;
            }
            else {
                return "".concat(formatHex(option.id), ": ").concat(option.title);
            }
        }, renderOption: function (props, option) { return (_jsxs(Box, __assign({ component: "li" }, props, { children: [formatHex(option.id), " ", option.title] }))); }, options: options, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: "".concat(title, ": ").concat((current === null || current === void 0 ? void 0 : current.title) || '?'), InputProps: __assign(__assign({}, params.InputProps), { type: 'search' }) }))); }, onChange: function (event, newValue) {
            if (newValue !== null && typeof newValue == 'string') {
                onChange(0);
            }
            else if (newValue !== null) {
                onChange(newValue.id);
            }
        } }));
}
