import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Divider, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useGetProfileQuery } from '../../../../api/profileApi';
import { useMessageHandler } from '../../../../context/MessageContext';
import { PricerMenuIcon } from './ProfileMenu.styles';
import { ProfileMenuButton } from './ProfileMenuButton';
import ProfileMenuHeader from './ProfileMenuHeader';
var getInitials = function (firstName, lastName, email) {
    var firstInitial = firstName.charAt(0);
    var lastInitial = lastName.charAt(0);
    if (firstInitial && lastInitial) {
        return "".concat(firstInitial).concat(lastInitial);
    }
    var emailName = email.split('@')[0].toUpperCase();
    if (emailName.includes('.')) {
        var _a = emailName.split('.'), first = _a[0], last = _a[1];
        if (first && last) {
            return "".concat(first.charAt(0)).concat(last.charAt(0));
        }
    }
    var emailInitial = email.charAt(0);
    if (emailInitial) {
        return emailInitial;
    }
    return '??';
};
var getName = function (firstName, lastName, email) {
    if (firstName && lastName) {
        return "".concat(firstName, " ").concat(lastName);
    }
    var emailName = email.split('@')[0];
    return emailName.replace('.', ' ');
};
export default function ProfileMenu() {
    var logout = useAuth0().logout;
    var addError = useMessageHandler().addError;
    var _a = useGetProfileQuery(), profile = _a.data, isError = _a.isError;
    var profileOrEmpty = profile || {
        givenName: 'Unknown',
        familyName: 'User',
        email: '',
        tenant: { name: 'Unknown' }
    };
    var initials = getInitials(profileOrEmpty.givenName, profileOrEmpty.familyName, profileOrEmpty.email);
    var name = getName(profileOrEmpty.givenName, profileOrEmpty.familyName, profileOrEmpty.email);
    var tenantName = profileOrEmpty.tenant.name || '';
    var doLogout = function () {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch profile');
        }
    }, [isError, addError]);
    return (_jsxs(ProfileMenuButton, { initials: initials, children: [_jsx(ProfileMenuHeader, { name: name, tenantName: tenantName }), _jsx(Divider, {}), _jsxs(MenuItem, { component: "a", href: "https://admin.console.dev.pricer-plaza.com/profile", children: [_jsx(PricerMenuIcon, { children: _jsx(ManageAccountsIcon, { fontSize: "small" }) }), "Profile"] }), _jsx(Divider, {}), _jsxs(MenuItem, { onClick: doLogout, children: [_jsx(PricerMenuIcon, { children: _jsx(LogoutIcon, {}) }), "Logout"] })] }));
}
