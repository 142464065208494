import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { transmissionApi } from '../../../../api/api';
import { useAppDispatch } from '../../../../store/store';
export function ZoneToolbar(_a) {
    var isFetching = _a.isFetching;
    var dispatch = useAppDispatch();
    var navigator = useNavigate();
    var onRefresh = function () {
        dispatch(transmissionApi.util.invalidateTags(['Messages', 'Message']));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "outlined", color: "secondary", onClick: function () { return navigator('/zones/add'); }, children: _jsx(AddIcon, {}) }), _jsx(Button, { variant: "outlined", color: "secondary", onClick: onRefresh, children: _jsx(RefreshIcon, {}) }), isFetching && (_jsx(_Fragment, { children: _jsx(CircularProgress, { size: "2em" }) }))] }));
}
