var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Paper, Switch, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetIRZonesQuery, useListAccessPointsQuery, useSaveIRZoneMutation } from '../../../../api/api';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { OkCancelArea } from '../../../../components/atoms/form/OkCancelArea';
import { useMessageHandler } from '../../../../context/MessageContext';
export function EditZoneView() {
    var _this = this;
    var navigator = useNavigate();
    var addApiError = useMessageHandler().addApiError;
    var _a = React.useState(''), title = _a[0], setTitle = _a[1];
    var _b = React.useState([]), includedAccessPoints = _b[0], setIncludedAccessPoints = _b[1];
    var id = useParams().id;
    var accessPoints = useListAccessPointsQuery({
        page: 0,
        size: 100
    }).data;
    var zones = useGetIRZonesQuery().data;
    useEffect(function () {
        var zone = (zones === null || zones === void 0 ? void 0 : zones.find(function (z) { return z.id === id; })) || undefined;
        if (zone) {
            setTitle(zone.title);
            setIncludedAccessPoints(zone.accessPoints || []);
        }
    }, [zones, id]);
    var saveZone = useSaveIRZoneMutation()[0];
    var onSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveZone({
                            id: id,
                            title: title,
                            accessPoints: includedAccessPoints
                        }).unwrap()];
                case 1:
                    _a.sent();
                    navigator('/zones');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to save ".concat(title), e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var clickAp = function (ap, check) {
        var aps = includedAccessPoints.filter(function (a) { return a !== ap.id; });
        if (check) {
            aps.push(ap.id);
        }
        setIncludedAccessPoints(aps);
    };
    return (_jsx(Paper, { variant: "outlined", children: _jsxs(FormArea, { title: "Edit zone", children: [_jsx(TextField, { label: "Title", variant: "outlined", value: title, onChange: function (e) { return setTitle(e.target.value); }, autoFocus: true }), _jsxs(FormControl, { component: "fieldset", variant: "standard", children: [_jsx(FormLabel, { component: "legend", children: "Include access-points" }), accessPoints &&
                            accessPoints.content.map(function (ap) { return (_jsx(FormGroup, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: includedAccessPoints.includes(ap.id), onChange: function (e, check) { return clickAp(ap, check); }, name: "gilad" }), label: ap.name }) }, ap.id)); }), _jsx(FormHelperText, { children: "Any access point included here will be removed from all other zones." })] }), _jsx(OkCancelArea, { onCancel: function () { return navigator('/zones'); }, onOk: onSave, okTitle: "Save" })] }) }));
}
