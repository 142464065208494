import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { transmissionApi, useGetLabelTypeQuery } from '../../../../api/api';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
import { LabelTypeView } from '../molecules/LabelTypeView';
export function ShowLabelVersionView() {
    var _a = useParams(), plType = _a.plType, hardwareId = _a.hardwareId, softwareId = _a.softwareId;
    var addError = useMessageHandler().addError;
    var dispatch = useAppDispatch();
    var s2n = function (s) { return +(s || '0'); };
    var _b = useGetLabelTypeQuery({
        plType: s2n(plType),
        hardwareVersion: s2n(hardwareId),
        softwareVersion: s2n(softwareId)
    }), labelType = _b.data, isFetching = _b.isFetching, isError = _b.isError;
    useEffect(function () {
        if (isError) {
            addError("Failed to fetch versions");
        }
    }, [isError, addError]);
    var softwareRevision = labelType
        ? (+labelType.softwareRevision).toString(16)
        : '';
    var hardwareVersion = labelType ? labelType.hardwareRevision : '';
    var subTitle = softwareRevision && hardwareVersion
        ? " / ".concat(hardwareVersion, " - ").concat(softwareRevision)
        : '';
    var refreshApi = function () {
        dispatch(transmissionApi.util.invalidateTags(['LabelTypes']));
    };
    var name = labelType ? labelType.name : '?';
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch access-point", wrapPaper: false, toolbar: _jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/label-types/".concat(plType) }), plType, ": ", name, " ", subTitle, _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching })] }), children: labelType && _jsx(LabelTypeView, { labelType: labelType }) }));
}
