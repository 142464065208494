import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ErrorIcon from '@mui/icons-material/Error';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ImageIcon from '@mui/icons-material/Image';
import MemoryIcon from '@mui/icons-material/Memory';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { transmissionApi } from '../../../../api/api';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { useAppDispatch } from '../../../../store/store';
export function MessageToolbar(_a) {
    var isFetching = _a.isFetching, filter = _a.filter, onSetFilter = _a.onSetFilter;
    var dispatch = useAppDispatch();
    var onRefresh = function () {
        dispatch(transmissionApi.util.invalidateTags(['Messages', 'Message']));
    };
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { sx: { display: { xs: 'none', sm: 'none', md: 'block' } }, label: "Barcode", variant: "outlined", size: "small", value: filter.startsWith('barcode:') ? filter.split(':')[1] : '', onChange: function (e) { return onSetFilter("barcode:".concat(e.target.value)); } }), _jsxs(ToggleButtonGroup, { value: filter, sx: { display: { xs: 'none', sm: 'block' } }, exclusive: true, onChange: function (e, v) { return onSetFilter(v); }, "aria-label": "filter", children: [_jsx(ToggleButton, { value: "", "aria-label": "left aligned", children: _jsx(FilterAltOffIcon, {}) }), _jsx(ToggleButton, { value: "type:command", "aria-label": "centered", children: _jsx(TaskAltIcon, {}) }), _jsx(ToggleButton, { value: "type:write", "aria-label": "right aligned", children: _jsx(ImageIcon, {}) }), _jsx(ToggleButton, { value: "type:writeObject", "aria-label": "justified", children: _jsx(MemoryIcon, {}) }), _jsx(ToggleButton, { value: "type:info", "aria-label": "justified", children: _jsx(PermDeviceInformationIcon, {}) }), _jsx(ToggleButton, { value: "status:error", "aria-label": "justified", children: _jsx(ErrorIcon, {}) }), _jsx(ToggleButton, { value: "status:pending", "aria-label": "justified", children: _jsx(PendingActionsIcon, {}) })] }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: onRefresh, isFetching: isFetching })] }));
}
