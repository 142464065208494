var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { TopicSelector } from '../atoms/TopicSelector';
import { ALL_TOPICS, INBOUND, OUTBOUND } from '../topics';
var equalsIgnoreOrder = function (a, b) {
    if (a.length !== b.length)
        return false;
    var uniqueValues = new Set(__spreadArray(__spreadArray([], a, true), b, true));
    var _loop_1 = function (v) {
        var aCount = a.filter(function (e) { return e === v; }).length;
        var bCount = b.filter(function (e) { return e === v; }).length;
        if (aCount !== bCount)
            return { value: false };
    };
    for (var _i = 0, _a = Array.from(uniqueValues); _i < _a.length; _i++) {
        var v = _a[_i];
        var state_1 = _loop_1(v);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return true;
};
var shouldBeLocked = function (type) {
    return type === 'service' || type === 'access-point' || type === 'full';
};
export function TopicPane(_a) {
    var read = _a.read, write = _a.write, onChange = _a.onChange;
    var _b = React.useState('custom'), topicType = _b[0], setTopicType = _b[1];
    var _c = React.useState(true), topicLocked = _c[0], setTopicLocked = _c[1];
    useEffect(function () {
        var isService = equalsIgnoreOrder(read, INBOUND) && equalsIgnoreOrder(write, OUTBOUND);
        var isAp = equalsIgnoreOrder(read, OUTBOUND) && equalsIgnoreOrder(write, INBOUND);
        var isFull = equalsIgnoreOrder(read, ALL_TOPICS) &&
            equalsIgnoreOrder(write, ALL_TOPICS);
        var initialType = isAp
            ? 'access-point'
            : isService
                ? 'service'
                : isFull
                    ? 'full'
                    : 'custom';
        setTopicType(initialType);
        setTopicLocked(shouldBeLocked(initialType));
    }, [read, write]);
    var setType = function (event) {
        setTopicType(event.target.value);
        setTopicLocked(shouldBeLocked(event.target.value));
        if (event.target.value === 'service') {
            onChange(INBOUND, OUTBOUND);
        }
        else if (event.target.value === 'access-point') {
            onChange(OUTBOUND, INBOUND);
        }
        else if (event.target.value === 'full') {
            onChange(ALL_TOPICS, ALL_TOPICS);
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(Stack, { sx: { m: 3 }, direction: "column", spacing: 3, children: [_jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Client type" }), _jsxs(RadioGroup, { onChange: setType, value: topicType, children: [_jsx(FormControlLabel, { value: "access-point", control: _jsx(Radio, {}), label: "Access-point" }), _jsx(FormControlLabel, { value: "service", control: _jsx(Radio, {}), label: "Service" }), _jsx(FormControlLabel, { value: "full", control: _jsx(Radio, {}), label: "Full access" }), _jsx(FormControlLabel, { value: "custom", control: _jsx(Radio, {}), label: "Custom" })] })] }), _jsx(TopicSelector, { title: "Read access", topics: read, onChange: function (value) { return onChange(value, write); }, disabled: topicLocked }), _jsx(TopicSelector, { title: "Write access", topics: write, onChange: function (value) { return onChange(read, value); }, disabled: topicLocked })] }) }));
}
