var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { transmissionApi } from '../../../api/api';
import { labelApi } from '../../../api/labelApi';
import { useIRBasestationContext } from '../../../context/IRBasestationContext';
import { useAppDispatch } from '../../../store/store';
export function ConnectButton() {
    var _this = this;
    var _a = useIRBasestationContext(), connect = _a.connect, disconnect = _a.disconnect, isIrConnected = _a.isIrConnected, getPorts = _a.getPorts;
    var dispatch = useAppDispatch();
    var _b = React.useState(-1), port = _b[0], setPort = _b[1];
    var _c = React.useState([]), ports = _c[0], setPorts = _c[1];
    useEffect(function () {
        var i = 0;
        getPorts().then(function (ports) {
            setPorts(ports.map(function () { return i++; }));
            setPort(ports.length - 1);
        });
    }, [getPorts]);
    var onConnect = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, connect(port)];
                case 1:
                    _a.sent();
                    setTimeout(function () {
                        dispatch(labelApi.util.invalidateTags(['Labels', 'Label']));
                        dispatch(transmissionApi.util.invalidateTags(['AccessPoint', 'AccessPoints']));
                    }, 1500);
                    return [2 /*return*/];
            }
        });
    }); };
    var onClickDisconnect = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, disconnect()];
                case 1:
                    _a.sent();
                    dispatch(labelApi.util.invalidateTags(['Labels', 'Label']));
                    dispatch(transmissionApi.util.invalidateTags(['AccessPoint', 'AccessPoints']));
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [!isIrConnected && (_jsxs(Stack, { direction: "row", spacing: 1, children: [_jsxs(FormControl, { children: [_jsx(InputLabel, { id: "page-label", children: "Port" }), _jsxs(Select, { sx: { maxWidth: 400 }, labelId: "page-label", size: "small", value: port, label: "Port", onChange: function (e) { return setPort(+e.target.value); }, children: [_jsx(MenuItem, { value: "-1", children: "New port" }, "new"), ports.map(function (i) { return (_jsxs(MenuItem, { value: i, children: ["Port ", i + 1] }, "port-".concat(i))); })] })] }), _jsx(Button, { variant: "contained", onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            return [2 /*return*/, onConnect()];
                        }); }); }, children: "Connect" })] })), isIrConnected && (_jsx(Stack, { direction: "row", spacing: 3, children: _jsx(Button, { variant: "contained", onClick: onClickDisconnect, children: "Disconnect" }) }))] }));
}
