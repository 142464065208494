export var ROLE_MAPPING = {
    'pricer-admin': [
        'tenant:read',
        'connection:update',
        'connection:create',
        'connection:delete'
    ],
    admin: [
        'tenant:read',
        'connection:update',
        'connection:create',
        'connection:delete'
    ],
    'pricer-editor': ['tenant:read', 'connection:update', 'connection:create'],
    editor: ['tenant:read', 'connection:update'],
    'pricer-viewer': ['pricer-viewer:only', 'pricer-profile:only'],
    'pricer-profile': ['pricer-profile:only']
};
