import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import React from 'react';
import { GroupDetails } from '../molecules/GroupDetails';
import { GroupFlash } from '../molecules/GroupFlash';
import { GroupIcons } from '../molecules/GroupIcons';
import { Log } from '../molecules/Log';
import { useLabel } from '../views/ManageLabelView';
export function GroupDetailsView() {
    var _a = useLabel(), label = _a.label, target = _a.target;
    return (_jsx(Stack, { sx: { m: 0 }, direction: "column", spacing: 3, children: _jsxs("div", { children: [_jsx(GroupDetails, { label: label, target: target }), _jsx(GroupIcons, { label: label, target: target }), _jsx(GroupFlash, { label: label, target: target }), _jsx(Log, { label: label })] }) }));
}
