import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, TextField } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
var formatHex = function (val) { return val.toString(16).padStart(2, '0'); };
var formatHexString = function (arr) {
    var result = '';
    var i = 0;
    for (var _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
        var val = arr_1[_i];
        result += formatHex(val) + ' ';
        if (i++ % 16 === 15) {
            result += '\n';
        }
    }
    return result;
};
export function HexEditor(_a) {
    var data = _a.data, onChange = _a.onChange, selection = _a.selection, onSelectionChange = _a.onSelectionChange;
    var _b = React.useState(formatHexString(data)), hexString = _b[0], setHexString = _b[1];
    var _c = React.useState(''), warning = _c[0], setWarning = _c[1];
    var _d = React.useState(-1), selectionStart = _d[0], setSelectionStart = _d[1];
    var _e = React.useState(-1), selectionEnd = _e[0], setSelectionEnd = _e[1];
    var updateSelection = useCallback(function (selection) {
        if (selectionEnd === -1 || selectionStart === -1) {
            return;
        }
        var str = hexString.substring(0, selectionStart) +
            selection.map(function (val) { return formatHex(val); }).join(' ') +
            ' ' +
            hexString.substring(selectionEnd);
        setHexString(str);
    }, [selectionStart, selectionEnd, hexString]);
    useEffect(function () {
        updateSelection(selection);
    }, [selection, updateSelection]);
    var parseHexString = function (str) {
        var bytes = str.trim().split(' ');
        var invalidBytes = [];
        var result = [];
        for (var _i = 0, bytes_1 = bytes; _i < bytes_1.length; _i++) {
            var byte = bytes_1[_i];
            var val = parseInt(byte, 16);
            if (val > 255 || isNaN(val)) {
                invalidBytes.push(byte);
            }
            else {
                result.push(val);
            }
        }
        if (invalidBytes.length > 0) {
            setWarning(invalidBytes.join(', ') + ' are invalid hex values');
        }
        else {
            setWarning('');
        }
        return result;
    };
    var onUpdate = function (value) {
        setHexString(value);
        var data = parseHexString(value);
        onChange(data);
    };
    var onBlur = function () {
        if (warning.length > 0) {
            return;
        }
        var data = parseHexString(hexString);
        onChange(data);
        setHexString(formatHexString(data));
    };
    var disableSelection = function () {
        setSelectionStart(-1);
        setSelectionEnd(-1);
        onSelectionChange([]);
    };
    var inputRef = useRef();
    var updateSelectionStart = function () {
        if (warning.length > 0) {
            return;
        }
        if (inputRef === undefined || inputRef.current === undefined) {
            disableSelection();
            return;
        }
        var elem = inputRef.current;
        if (elem.textContent === undefined ||
            elem.selectionStart === undefined ||
            elem.selectionEnd === undefined) {
            disableSelection();
            return;
        }
        if (elem.selectionStart === elem.selectionEnd) {
            disableSelection();
            return;
        }
        var start = elem.selectionStart;
        var end = elem.selectionEnd;
        while (elem.textContent[start] === ' ') {
            if (start > end) {
                disableSelection();
                return;
            }
            start++;
        }
        while (start > 0 && elem.textContent[start - 1] !== ' ') {
            if (start > end) {
                disableSelection();
                return;
            }
            start--;
        }
        while (elem.textContent[end] === ' ') {
            if (end < start) {
                disableSelection();
                return;
            }
            end--;
        }
        while (end < elem.textContent.length && elem.textContent[end - 1] !== ' ') {
            if (end < start) {
                disableSelection();
                return;
            }
            end++;
        }
        if (start === end || start < 0 || start === end - 1 || start > end) {
            disableSelection();
            return;
        }
        var highlight = elem.textContent.substring(start, end - 1);
        if (highlight.length > 14) {
            disableSelection();
            return;
        }
        var bytes = parseHexString(highlight);
        if (bytes.length > 4) {
            disableSelection();
            return;
        }
        if (start === end || highlight.trim().length === 0) {
            disableSelection();
            return;
        }
        setSelectionStart(start);
        setSelectionEnd(end);
        onSelectionChange(bytes);
    };
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { inputProps: { spellCheck: false, style: { fontFamily: 'monospace' } }, fullWidth: true, label: "Enter hex", multiline: true, inputRef: inputRef, rows: 4, value: hexString, sx: { fontFamily: 'monospace' }, onChange: function (e) { return onUpdate(e.target.value); }, onBlur: onBlur, onSelect: function () {
                    updateSelectionStart();
                } }), warning.length > 0 && _jsx(Alert, { severity: "error", children: warning })] }));
}
