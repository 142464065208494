var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '../../../../common/assets/github-markdown.css';
import { Stack, Switch, TextField } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useExplainFramesMutation } from '../../../../api/api';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
export function ExplainFramesView() {
    var _this = this;
    var addApiError = useMessageHandler().addApiError;
    var _a = React.useState(''), source = _a[0], setSource = _a[1];
    var _b = React.useState(''), target = _b[0], setTarget = _b[1];
    var _c = React.useState(['esl-frame']), include = _c[0], setInclude = _c[1];
    var explainFrames = useExplainFramesMutation()[0];
    var onExplainFrames = function () { return __awaiter(_this, void 0, void 0, function () {
        var result, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, explainFrames({
                            source: source,
                            include: include.join(',')
                        }).unwrap()];
                case 1:
                    result = _a.sent();
                    setTarget(result);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError('Failed to explain frames', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(SimplePageContent, { toolbar: _jsx(_Fragment, { children: _jsx(BackButton, { path: "/tools" }) }), children: _jsxs(FormArea, { title: 'Explain frames', children: [_jsx(TextField, { label: "Source", fullWidth: true, rows: 10, placeholder: "Paste data here", multiline: true, onChange: function (e) { return setSource(e.target.value); } }), _jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(Switch, { checked: include.includes('trx-packet'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check
                                        ? __spreadArray(__spreadArray([], e, true), ['trx-packet'], false) : e.filter(function (i) { return i !== 'trx-packet'; });
                                });
                            } }), ' ', "TrxD Packet", _jsx(Switch, { checked: include.includes('trx-frame'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['trx-frame'], false) : e.filter(function (i) { return i !== 'trx-frame'; });
                                });
                            } }), ' ', "TrxD Frame", _jsx(Switch, { checked: include.includes('esl-frame'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['esl-frame'], false) : e.filter(function (i) { return i !== 'esl-frame'; });
                                });
                            } }), ' ', "Esl Frame", _jsx(Switch, { checked: include.includes('all-data'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['all-data'], false) : e.filter(function (i) { return i !== 'all-data'; });
                                });
                            } }), ' ', "Full payloads"] }), _jsx(PricerButton, { onClick: onExplainFrames, children: "Explain" }), _jsx("article", { className: "markdown-body", children: _jsx(ReactMarkdown, { children: target, remarkPlugins: [remarkGfm] }) })] }) }));
}
