var _a;
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { transmissionApi } from '../api/api';
import { itemApi } from '../api/itemApi';
import { labelApi } from '../api/labelApi';
import { profileApi } from '../api/profileApi';
import { authSlice } from '../common/authSlice';
export var store = configureStore({
    reducer: (_a = {},
        _a[authSlice.name] = authSlice.reducer,
        _a[transmissionApi.reducerPath] = transmissionApi.reducer,
        _a[profileApi.reducerPath] = profileApi.reducer,
        _a[labelApi.reducerPath] = labelApi.reducer,
        _a[itemApi.reducerPath] = itemApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware()
            .concat(transmissionApi.middleware)
            .concat(profileApi.middleware)
            .concat(labelApi.middleware)
            .concat(itemApi.middleware);
    }
});
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
