import '@fontsource/raleway';
export var themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#164713',
            light: '#2b7b01'
        },
        secondary: {
            main: '#3178d4'
        },
        background: {
            default: '#f8f8f8'
        },
        error: {
            main: '#e53800'
        },
        warning: {
            main: '#f0350c'
        },
        success: {
            main: '#2d7b01'
        }
    },
    typography: {
        fontFamily: 'Raleway'
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                variant: 'contained'
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#107c10',
                    fontWeight: '700',
                    textTransform: 'none',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#107c10'
                    }
                },
                textError: {
                    color: '#e53800'
                },
                outlinedError: {
                    borderColor: '#e53800',
                    color: '#e53800',
                    '&:hover': {
                        borderColor: '#e53800'
                    }
                },
                outlined: {
                    color: '#387A26',
                    background: '#FFFFFF',
                    border: '1.5px solid #107c10',
                    '&:hover': {
                        background: '#f1f6fa',
                        border: '1.5px solid #107c10'
                    }
                },
                text: {
                    color: '#107c10',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        background: '#f1f6fa'
                    }
                },
                outlinedSecondary: {
                    color: '#707070',
                    borderColor: '#707070',
                    '&:hover': {
                        color: '#070707',
                        borderColor: '#070707'
                    }
                }
            }
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                size: 'small'
            },
            styleOverrides: {
                root: {
                    color: '#387A26',
                    background: '#FFFFFF',
                    border: '1.5px solid #707070',
                    '&:hover': {
                        color: '#070707',
                        background: '#f1f6fa',
                        borderColor: '#070707'
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingRight: '15px',
                    paddingLeft: '15px'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    color: '#1c1b1f',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#2d7b01',
                        height: 3
                    }
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                action: {
                    color: '#cebdff'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: '615d66',
                    borderRadius: '10px',
                    backgroundColor: '#f8f8f8',
                    border: '1.5px solid #cecfce',
                    fontWeight: '600',
                    '&:hover': {
                        background: '#eaeaea'
                    }
                },
                colorPrimary: {
                    color: 'black',
                    backgroundColor: '#b4c8b9',
                    border: 'none',
                    '&:hover': {
                        background: '#a8baad'
                    }
                },
                colorSecondary: {
                    color: 'black'
                },
                avatar: {
                    borderRadius: '16px'
                },
                icon: {
                    color: '#054816'
                },
                deleteIcon: {
                    color: 'black'
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    backgroundColor: '#e3e7e4',
                    color: '#054816',
                    '&:hover': {
                        background: '#cfd9d2'
                    },
                    '&.Mui-disabled': {
                        color: '#979799',
                        backgroundColor: '#e3e3e4'
                    }
                },
                primary: {
                    backgroundColor: '#e1e9e3'
                },
                secondary: {
                    color: 'black',
                    backgroundColor: '#b4c8b9'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    color: '#054816',
                    width: '300px',
                    '&.Mui-selected': {
                        backgroundColor: '#e1e9e3'
                    },
                    '&:hover': {
                        backgroundColor: '#e1e9e3'
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 42,
                    height: 26,
                    padding: 0
                },
                switchBase: {
                    padding: 0,
                    margin: 3,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: '#fff'
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        opacity: 1
                    },
                    '&.MuiSwitch-track': {
                        opacity: 1
                    },
                    '&.Mui-disabled': {
                        color: '#f8f8f8'
                    },
                    '&.Mui-checked.Mui-disabled': {
                        color: '#f8f8f8'
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        backgroundColor: '#e9e9ea',
                        opacity: 1
                    }
                },
                colorPrimary: {
                    color: 'white',
                    '&:hover': {
                        color: '#e1e9e3'
                    }
                },
                track: {
                    borderRadius: '20px',
                    backgroundColor: '#054816',
                    opacity: 1
                }
            }
        }
    }
};
