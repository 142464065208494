var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import QrCodeIcon from '@mui/icons-material/QrCode';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import { List, Paper, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useListLabelTypesVersionsQuery } from '../../../../api/api';
import { useAddLabelMutation } from '../../../../api/labelApi';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { OkCancelArea } from '../../../../components/atoms/form/OkCancelArea';
import { ListItemInfo } from '../../../../components/atoms/ListItemInfo';
import { useMessageHandler } from '../../../../context/MessageContext';
import { getPricerLabelType } from '../../label';
export function AddLabelView() {
    var _this = this;
    var _a;
    var navigator = useNavigate();
    var _b = useMessageHandler(), addError = _b.addError, addApiError = _b.addApiError;
    var _c = React.useState(''), barcode = _c[0], setBarcode = _c[1];
    var addLabel = useAddLabelMutation()[0];
    var plType = '' + getPricerLabelType(barcode);
    var _d = useListLabelTypesVersionsQuery({
        plType: plType,
        size: 1
    }, { skip: plType === '0' }), versions = _d.data, isError = _d.isError, error = _d.error;
    var labelType = (versions === null || versions === void 0 ? void 0 : versions.content) && ((_a = versions === null || versions === void 0 ? void 0 : versions.content) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? versions === null || versions === void 0 ? void 0 : versions.content[0]
        : undefined;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch label-types');
        }
    }, [isError, addError, error]);
    var onSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addLabel({
                            barcode: barcode
                        }).unwrap()];
                case 1:
                    _a.sent();
                    navigator("/labels/".concat(barcode));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to save ".concat(barcode), e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var type = labelType ? "".concat(labelType.plType, " ").concat(labelType.name) : 'unknown';
    return (_jsx(Paper, { variant: "outlined", children: _jsxs(FormArea, { title: "Add label", children: [_jsx(TextField, { label: "Barcode", variant: "outlined", value: barcode, onChange: function (e) { return setBarcode(e.target.value); }, autoFocus: true }), _jsxs(List, { children: [_jsx(ListItemInfo, { title: "Barcode", icon: _jsx(QrCodeIcon, {}), value: barcode }), _jsx(ListItemInfo, { title: "Pricer Label", icon: _jsx(SmartScreenIcon, {}), value: type })] }), _jsx(OkCancelArea, { onCancel: function () { return navigator('/labels'); }, onOk: onSave, okTitle: "Add" })] }) }));
}
