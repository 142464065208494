var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './helpers';
export var getImageQueryParam = function (param) {
    var resizeUrl = param.resize
        ? "&resize=true&width=".concat(param.width, "&height=").concat(param.height)
        : '';
    var overlayBarcode = param.overlayBarcode ? "&overlay-barcode=true" : '';
    var target = param.target ? "&target=".concat(param.target) : '';
    var encodedX = param.x ? "&x=".concat(param.x) : '';
    var encodedY = param.y ? "&y=".concat(param.y) : '';
    return "?dither=".concat(param.dither, "&compression=").concat(param.compression).concat(resizeUrl).concat(encodedX).concat(encodedY).concat(overlayBarcode).concat(target);
};
export var labelApi = createApi({
    reducerPath: 'labelApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    }),
    tagTypes: ['Labels', 'Label', 'Command', 'Commands'],
    endpoints: function (builder) { return ({
        listLabels: builder.query({
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.size, size = _c === void 0 ? 20 : _c, _d = _a.filter, filter = _d === void 0 ? '' : _d;
                return ({
                    url: "transmission/api/v1/labels?page=".concat(page, "&size=").concat(size, "&filter=").concat(filter),
                    responseHandler: responseHandler
                });
            },
            transformResponse: (transformPaginatedResponse),
            providesTags: ['Labels']
        }),
        getLabel: builder.query({
            query: function (id) { return ({
                url: "transmission/api/v1/labels/".concat(id),
                responseHandler: responseHandler
            }); },
            providesTags: function (result, error, id) { return [{ type: 'Label', id: id }]; }
        }),
        updateLabel: builder.mutation({
            query: function (label) { return ({
                url: "transmission/api/v1/labels/".concat(label.barcode),
                method: 'PUT',
                body: __assign(__assign({}, label), { barcode: undefined }),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.barcode;
                return [
                    { type: 'Label', id: id },
                    { type: 'Labels' }
                ];
            }
        }),
        addLabel: builder.mutation({
            query: function (label) { return ({
                url: "transmission/api/v1/labels",
                method: 'POST',
                body: __assign({}, label),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.barcode;
                return [
                    { type: 'Label', id: id },
                    { type: 'Labels' }
                ];
            }
        }),
        addGroup: builder.mutation({
            query: function (label) { return ({
                url: "transmission/api/v1/groups",
                method: 'POST',
                body: __assign({}, label),
                responseHandler: responseHandler
            }); },
            invalidatesTags: function (response) { return [
                { type: 'Label', id: response === null || response === void 0 ? void 0 : response.barcode },
                { type: 'Labels' }
            ]; }
        }),
        deleteLabel: builder.mutation({
            query: function (barcode) { return ({
                url: "transmission/api/v1/labels/".concat(barcode),
                method: 'DELETE',
                responseHandler: 'text'
            }); },
            invalidatesTags: function () { return [{ type: 'Labels' }]; }
        }),
        pingLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/ping"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        sendRaw: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/send-raw"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        locateLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/locate"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        configureLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/configure"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        upgradeLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/upgrade"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        setupLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/setup"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        readSensor: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/read-sensor"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        flashLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/flash"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        groupFlashLabel: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/groups/".concat(command.barcode, "/flash"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        updateKeyCode: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/update-keycode"),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        switchPage: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/page/").concat(command.page),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined, page: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode },
                { type: 'Labels' }
            ]; }
        }),
        configureIcon: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/icon/").concat(command.icon),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined, icon: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        groupConfigureIcon: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/groups/".concat(command.barcode, "/icon/").concat(command.icon),
                responseHandler: responseHandler,
                method: 'POST',
                body: __assign(__assign({}, command), { barcode: undefined, icon: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        uploadTestImage: builder.mutation({
            query: function (command) { return ({
                url: "transmission/api/v1/labels/".concat(command.barcode, "/image/").concat(command.page, "/test-image"),
                responseHandler: responseHandler,
                method: 'PUT',
                body: __assign(__assign({}, command), { barcode: undefined, page: undefined })
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        uploadImage: builder.mutation({
            query: function (data) { return ({
                url: "transmission/api/v1/labels/".concat(data.barcode, "/image/").concat(data.page).concat(getImageQueryParam(data)),
                responseHandler: responseHandler,
                method: 'POST',
                headers: {
                    'content-type': 'image/png'
                },
                body: data.image
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        uploadFirmware: builder.mutation({
            query: function (data) { return ({
                url: "transmission/api/v1/labels/".concat(data.barcode, "/firmware"),
                responseHandler: responseHandler,
                method: 'POST',
                headers: {
                    'content-type': 'application/octet-stream'
                },
                body: data.firmware
            }); },
            invalidatesTags: function (result, error, command) { return [
                { type: 'Label', id: command.barcode }
            ]; }
        }),
        getCommandStatus: builder.query({
            query: function (requestId) { return ({
                url: "transmission/api/v1/commands/".concat(requestId),
                responseHandler: responseHandler
            }); },
            providesTags: ['Commands']
        })
    }); }
});
export var fetchPreviewImage = function (image_1, token_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([image_1, token_1], args_1, true), void 0, function (image, token, pricer) {
        var response, size;
        var _a;
        if (pricer === void 0) { pricer = false; }
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch("".concat(getConfig().apiBase, "/transmission/api/v1/labels/").concat(image.barcode, "/preview/").concat(image.page).concat(getImageQueryParam(image), "&pricerFormat=").concat(pricer ? 'true' : 'false'), {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'image/png',
                            Accept: 'image/png',
                            Authorization: "Bearer ".concat(token)
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: image.image
                    })];
                case 1:
                    response = _b.sent();
                    size = response.headers.get('X-Pricer-Image-Size') || 0;
                    _a = { size: size };
                    return [4 /*yield*/, response.blob()];
                case 2: return [2 /*return*/, (_a.image = _b.sent(), _a)];
            }
        });
    });
};
export var useListLabelsQuery = labelApi.useListLabelsQuery, useGetLabelQuery = labelApi.useGetLabelQuery, usePingLabelMutation = labelApi.usePingLabelMutation, useFlashLabelMutation = labelApi.useFlashLabelMutation, useAddLabelMutation = labelApi.useAddLabelMutation, useDeleteLabelMutation = labelApi.useDeleteLabelMutation, useUploadImageMutation = labelApi.useUploadImageMutation, useUploadFirmwareMutation = labelApi.useUploadFirmwareMutation, useReadSensorMutation = labelApi.useReadSensorMutation, useConfigureLabelMutation = labelApi.useConfigureLabelMutation, useSwitchPageMutation = labelApi.useSwitchPageMutation, useConfigureIconMutation = labelApi.useConfigureIconMutation, useLocateLabelMutation = labelApi.useLocateLabelMutation, useSendRawMutation = labelApi.useSendRawMutation, useUpgradeLabelMutation = labelApi.useUpgradeLabelMutation, useSetupLabelMutation = labelApi.useSetupLabelMutation, useUpdateLabelMutation = labelApi.useUpdateLabelMutation, useUploadTestImageMutation = labelApi.useUploadTestImageMutation, useUpdateKeyCodeMutation = labelApi.useUpdateKeyCodeMutation, useAddGroupMutation = labelApi.useAddGroupMutation, useGroupFlashLabelMutation = labelApi.useGroupFlashLabelMutation, useGroupConfigureIconMutation = labelApi.useGroupConfigureIconMutation;
