import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardActionArea, CardContent, CardHeader, CardMedia, Chip, Grid, Stack } from '@mui/material';
import React from 'react';
import { getConfig } from '../../../../common/config';
import { useAuthentication } from '../../../../common/hooks/authentication';
export function LabelTypeListItem(_a) {
    var _b;
    var labelType = _a.labelType, onClick = _a.onClick;
    var token = useAuthentication().token;
    var title = labelType.plType;
    var subTitle = labelType.name;
    var url = "".concat(getConfig().apiBase, "/transmission/api/v1/label-types/").concat(labelType.plType, "/image.png?access_token=").concat(token);
    var colors = ((_b = labelType.display) === null || _b === void 0 ? void 0 : _b.colors) || 'BW';
    var hasRed = colors.includes('R');
    var hasYellow = colors.includes('Y');
    var graphic = (labelType === null || labelType === void 0 ? void 0 : labelType.type) === 'graphical';
    var rgbLed = (labelType === null || labelType === void 0 ? void 0 : labelType.attentionLed) === 'RGB';
    return (_jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(Card, { sx: { minWidth: { sm: 1 / 2, md: 2 / 3 } }, children: _jsxs(CardActionArea, { onClick: function () { return onClick(labelType); }, children: [_jsx(CardHeader, { title: title, subheader: subTitle }), _jsx(CardMedia, { component: "img", sx: { objectFit: 'scale-down', width: 1, height: '100px' }, image: url }), _jsx(CardContent, { children: _jsxs(Stack, { sx: { justifyContent: 'space-between' }, direction: "row", children: [_jsxs(Box, { children: [graphic && (_jsxs(_Fragment, { children: [_jsx(Chip, { label: "Black", size: "small", sx: { backgroundColor: 'black', color: 'white' } }), _jsx(Chip, { label: "White", size: "small", sx: { backgroundColor: 'white' } }), hasRed && (_jsx(Chip, { label: "Red", size: "small", sx: { backgroundColor: 'red' } })), hasYellow && (_jsx(Chip, { label: "Yellow", size: "small", sx: { backgroundColor: 'yellow' } }))] })), !graphic && (_jsx(Chip, { label: "Segment", size: "small", sx: { backgroundColor: 'black', color: 'white' } }))] }), _jsxs(_Fragment, { children: [rgbLed && (_jsx(Chip, { label: "RGB", size: "small", sx: { backgroundColor: 'white', color: 'red' } })), !rgbLed && (_jsx(Chip, { label: "Green", size: "small", sx: { backgroundColor: 'white', color: 'green' } }))] })] }) })] }) }) }));
}
