import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDeleteUserMutation } from '../../../../../api/api';
export function DeleteUserView() {
    var id = useParams().id;
    var navigator = useNavigate();
    var deleteUser = useDeleteUserMutation()[0];
    var onDelete = function () {
        deleteUser(id || '');
        navigator('/tools/keys');
    };
    return (_jsx(Paper, { variant: "outlined", sx: { minWidth: '500px' }, children: _jsxs(Stack, { sx: { m: 3 }, direction: "column", spacing: 3, children: [_jsxs(Typography, { variant: "h5", children: ["Delete user ", id] }), _jsx(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, children: _jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(Button, { variant: "outlined", onClick: function () { return navigator('/tools/keys'); }, children: "Cancel" }), _jsx(Button, { onClick: onDelete, children: "Delete" })] }) })] }) }));
}
