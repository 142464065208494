import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
export var readUploadFile = function (selectedFile) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onloadend = function () {
            if (reader.result === null) {
                reject('Failed to read file');
                return;
            }
            resolve(reader.result);
        };
        reader.readAsArrayBuffer(selectedFile);
    });
};
export function FileUpload(_a) {
    var title = _a.title, onUpload = _a.onUpload;
    var _b = React.useState(undefined), selectedFile = _b[0], setSelectedFile = _b[1];
    var handleCapture = function (_a) {
        var target = _a.target;
        if (target.files === undefined ||
            target.files === null ||
            target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(target.files[0]);
        onUpload(target.files).then(function (success) {
            if (success) {
                setSelectedFile(undefined);
                target.value = '';
            }
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx("input", { id: "firmwareImage", type: "file", style: { display: 'none' }, onChange: handleCapture }), _jsx(Tooltip, { title: title, children: _jsxs("label", { htmlFor: "firmwareImage", children: [_jsx(IconButton, { color: "primary", component: "span", children: _jsx(UploadFileIcon, { fontSize: "large" }) }), _jsx(Typography, { variant: "body1", component: "span", children: selectedFile ? selectedFile.name : title })] }) })] }));
}
