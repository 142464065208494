import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetIRZonesQuery } from '../../../../api/api';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { ZoneToolbar } from '../atoms/ZoneToolbar';
import { ZoneList } from '../organisms/ZoneList';
export function ZoneListView() {
    var navigator = useNavigate();
    var addError = useMessageHandler().addError;
    var gotoMessage = function (id) {
        navigator("/zones/".concat(id));
    };
    var _a = useGetIRZonesQuery(), zones = _a.data, isFetching = _a.isFetching, isError = _a.isError;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch messages');
        }
    }, [isError, addError]);
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch messages", hasPaper: false, toolbar: _jsx(ZoneToolbar, { isFetching: isFetching }), children: zones && _jsx(ZoneList, { zones: zones, goto: gotoMessage }) }));
}
