import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
export function BackButton(_a) {
    var path = _a.path;
    var navigator = useNavigate();
    return (_jsx(_Fragment, { children: _jsx(IconButton, { onClick: function () { return navigator(path); }, children: _jsx(ArrowBackIcon, {}) }) }));
}
