import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
var formatHex = function (val) { return val.toString(16).padStart(2, '0'); };
var formatBin = function (val) { return (val >>> 0).toString(2).padStart(8, '0'); };
export function ExplainHex(_a) {
    var inValue = _a.value, onChange = _a.onChange;
    var _b = React.useState(formatBin(inValue)), value = _b[0], setValue = _b[1];
    useEffect(function () {
        setValue(formatBin(inValue));
    }, [inValue]);
    return (_jsx(TextField, { label: "0x".concat(formatHex(parseInt(value, 2))), value: value, onBlur: function () { return onChange(parseInt(value, 2)); }, onChange: function (e) {
            setValue(e.target.value);
        } }));
}
