import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useListLabelTypesVersionsQuery } from '../../../../api/api';
import { useMessageHandler } from '../../../../context/MessageContext';
import { getPricerLabelType } from '../../label';
import { Flash } from '../molecules/Flash';
import { Icons } from '../molecules/Icons';
import { LabelDetailsVew } from '../molecules/LabelDetailsVew';
import { Links } from '../molecules/Links';
import { LocateMissingLabelWarning } from '../molecules/LocateMissingLabelWarning';
import { Log } from '../molecules/Log';
import { OpsView } from '../molecules/OpsView';
import { Pages } from '../molecules/Pages';
import { PhysicalCharacteristics } from '../molecules/PhysicalCharacteristics';
import { TransmissionQueue } from '../molecules/TransmissionQueue';
import { UpdateAvailableWarning } from '../molecules/UpdateAvailableWarning';
import { useLabel } from '../views/ManageLabelView';
export function LabelDetailsView() {
    var _a, _b;
    var _c = useLabel(), label = _c.label, found = _c.found, target = _c.target;
    var addError = useMessageHandler().addError;
    var plType = '' + getPricerLabelType(label.barcode);
    var _d = useListLabelTypesVersionsQuery({ plType: plType, size: 100 }), versions = _d.data, isError = _d.isError, error = _d.error;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch label-types');
        }
    }, [isError, addError, error]);
    var hardwareRevision = (_a = label === null || label === void 0 ? void 0 : label.hardwareRevision) === null || _a === void 0 ? void 0 : _a.value;
    var relevantVersions = hardwareRevision &&
        (versions === null || versions === void 0 ? void 0 : versions.content) &&
        versions.content.filter(function (v) { return "".concat(v.hardwareRevision) === hardwareRevision; });
    var exactPlType = relevantVersions && relevantVersions.length === 1
        ? relevantVersions[0]
        : undefined;
    var similarPlType = (versions === null || versions === void 0 ? void 0 : versions.content) && ((_b = versions === null || versions === void 0 ? void 0 : versions.content) === null || _b === void 0 ? void 0 : _b.length) > 0
        ? versions.content.reduce(function (a, b) {
            return a.softwareRevision > b.softwareRevision ? a : b;
        })
        : undefined;
    return (_jsxs(Stack, { sx: { m: 0 }, direction: "column", spacing: 3, children: [_jsx(LocateMissingLabelWarning, { label: label, found: found }), _jsx(UpdateAvailableWarning, { label: label, target: target, plType: exactPlType }), _jsxs("div", { children: [_jsx(PhysicalCharacteristics, { label: label, plType: similarPlType }), _jsx(OpsView, { label: label, target: target, plType: exactPlType }), _jsx(LabelDetailsVew, { label: label, target: target, plType: exactPlType }), _jsx(Icons, { label: label, target: target }), _jsx(Pages, { label: label, target: target }), _jsx(Flash, { label: label, target: target }), _jsx(Log, { label: label }), _jsx(Links, { label: label }), _jsx(TransmissionQueue, { label: label })] })] }));
}
