var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { OkCancelArea } from '../../../../components/atoms/form/OkCancelArea';
export function ItemEditor(_a) {
    var title = _a.title, buttonTitle = _a.buttonTitle, item = _a.item, onChange = _a.onChange, onSave = _a.onSave;
    var navigator = useNavigate();
    var doSave = function () {
        onSave(__assign({}, item));
    };
    return (_jsxs(FormArea, { title: title, children: [_jsx(TextField, { label: "External Id", variant: "outlined", value: item.externalId || '', onChange: function (e) { return onChange(__assign(__assign({}, item), { externalId: e.target.value })); } }), _jsx(TextField, { label: "Title", variant: "outlined", value: item.itemData['title'] || '', onChange: function (e) {
                    return onChange(__assign(__assign({}, item), { itemData: __assign(__assign({}, item.itemData), { title: e.target.value }) }));
                }, autoFocus: true }), _jsx(TextField, { label: "Name", variant: "outlined", value: item.itemData['name'] || '', onChange: function (e) {
                    return onChange(__assign(__assign({}, item), { itemData: __assign(__assign({}, item.itemData), { name: e.target.value }) }));
                } }), _jsx(TextField, { label: "Price", variant: "outlined", value: item.itemData['price'] || '', onChange: function (e) {
                    return onChange(__assign(__assign({}, item), { itemData: __assign(__assign({}, item.itemData), { price: e.target.value }) }));
                } }), _jsx(TextField, { label: "Image", variant: "outlined", value: item.itemData['image'] || '', onChange: function (e) {
                    return onChange(__assign(__assign({}, item), { itemData: __assign(__assign({}, item.itemData), { image: e.target.value }) }));
                } }), _jsx(OkCancelArea, { onCancel: function () { return navigator('/items'); }, onOk: doSave, okTitle: buttonTitle })] }));
}
