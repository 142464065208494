import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getRTKError } from '../../api/helpers';
import { useMessageHandler } from '../../context/MessageContext';
import { PricerToolbar } from '../atoms/toolbar/PricerToolbar';
import { ErrorDialog } from '../molecules/ErrorDialog';
export function SimplePageContent(_a) {
    var apiError = _a.apiError, _b = _a.errorMessage, errorMessage = _b === void 0 ? 'Error' : _b, _c = _a.isError, isError = _c === void 0 ? false : _c, toolbar = _a.toolbar, children = _a.children, _d = _a.hasPaper, hasPaper = _d === void 0 ? true : _d, _e = _a.wrapPaper, wrapPaper = _e === void 0 ? true : _e;
    var navigator = useNavigate();
    var _f = useMessageHandler(), addError = _f.addError, addApiError = _f.addApiError;
    var error = apiError !== undefined
        ? "".concat(errorMessage, ": ").concat(getRTKError(apiError))
        : errorMessage;
    useEffect(function () {
        if (isError && apiError) {
            addApiError(errorMessage, apiError);
        }
        else if (isError) {
            addError(errorMessage);
        }
    }, [isError, apiError, errorMessage, addError, addApiError]);
    if (!hasPaper) {
        return (_jsxs(_Fragment, { children: [isError && _jsx(ErrorDialog, { text: error, onClose: function () { return navigator('/'); } }), toolbar && (_jsx(PricerToolbar, { sx: { width: { xs: 1, md: 0.9 } }, children: toolbar })), children] }));
    }
    return (_jsxs(_Fragment, { children: [isError && (_jsx(ErrorDialog, { text: errorMessage, onClose: function () { return navigator('/'); } })), toolbar && (_jsx(PricerToolbar, { sx: { width: { xs: 1, md: 0.9 } }, children: toolbar })), wrapPaper && (_jsxs(Paper, { sx: { p: 3, width: { xs: 1, md: 0.9 } }, children: [" ", children, " "] })), !wrapPaper && _jsxs(Box, { sx: { width: { xs: 1, md: 0.9 } }, children: [" ", children] })] }));
}
