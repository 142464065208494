import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import PaletteIcon from '@mui/icons-material/Palette';
import { Slider, Stack } from '@mui/material';
import React from 'react';
export function FlashSlider(_a) {
    var color = _a.color, value = _a.value, onChange = _a.onChange;
    var icon = color === 'brightness' ? (_jsx(BrightnessHighIcon, {})) : (_jsx(PaletteIcon, { sx: { color: color } }));
    return (_jsxs(Stack, { spacing: 2, direction: "row", sx: { mb: 1 }, alignItems: "center", children: [icon, _jsx(Slider, { value: value, step: 1, marks: true, min: 0, max: 3, onChange: function (e, value) { return onChange(value); } })] }));
}
