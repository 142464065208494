import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Menu, Tooltip } from '@mui/material';
import React from 'react';
import { ColoredAvatar } from '../../../atoms/ColoredAvatar';
export function ProfileMenuButton(_a) {
    var initials = _a.initials, children = _a.children;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: "Account settings", children: _jsx(IconButton, { onClick: handleClick, size: "small", sx: { ml: 2 }, "aria-controls": open ? 'account-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, children: _jsx(ColoredAvatar, { initials: initials }) }) }), _jsx(Menu, { anchorEl: anchorEl, open: open, onClose: handleClose, onClick: handleClose, PaperProps: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }, transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, children: children })] }));
}
