import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
export var responseHandler = function (response) {
    if (response.status === 401 || response.status === 403) {
        return Promise.resolve({ error: response.statusText });
    }
    return Promise.resolve(response.json());
};
export var profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().profileApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: function (builder) { return ({
        getProfile: builder.query({
            query: function () { return ({
                url: 'api/v2/profile',
                method: 'GET',
                responseHandler: responseHandler
            }); }
        })
    }); }
});
export var useGetProfileQuery = profileApi.useGetProfileQuery;
