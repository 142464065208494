import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardActionArea, CardContent, CardHeader, Tooltip } from '@mui/material';
import React from 'react';
import { relativeTime, toIsoDate } from '../../../../common/helpers';
import { MessageBody } from './MessageBody';
import { MessageIcon } from './MessageIcon';
export function MessageListItem(_a) {
    var message = _a.message, onClick = _a.onClick;
    var title = "".concat(message.type, " - ").concat(message.barcode);
    var receivedDate = message.received
        ? ", Received: ".concat(toIsoDate(message.received))
        : '';
    var tooltip = "Sent: ".concat(toIsoDate(message.sent)).concat(receivedDate, ", Status: ").concat(message.status);
    return (_jsx(Card, { sx: { width: { sm: 1, md: 0.9 } }, children: _jsxs(CardActionArea, { onClick: function () { return onClick(message); }, children: [_jsx(Tooltip, { title: tooltip, children: _jsx(CardHeader, { avatar: _jsx(MessageIcon, { type: message.type, status: message.status }), title: title, subheader: relativeTime(message.sent) }) }), _jsx(CardContent, { children: _jsx(MessageBody, { message: message }) })] }) }, message.id));
}
