import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { useListMessagesQuery } from '../../../../api/api';
import { MessageListItem } from './MessageListItem';
export function Log(_a) {
    var label = _a.label;
    var navigator = useNavigate();
    var _b = useLocalStorage('label.show.log', false), expanded = _b[0], setExpanded = _b[1];
    var _c = useListMessagesQuery({
        page: 0,
        size: 5,
        filter: "barcode:".concat(label.barcode)
    }), messages = _c.data, isFetching = _c.isFetching, isError = _c.isError;
    var gotoMessage = function (id) {
        navigator("/messages/".concat(id, "?filter=barcode:").concat(label.barcode));
    };
    var gotoMessages = function () {
        navigator("/messages/?filter=barcode:".concat(label.barcode));
    };
    return (_jsxs(Accordion, { expanded: expanded, onChange: function (e, isExpanded) { return setExpanded(isExpanded); }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "icons-content", id: "icons-header", children: _jsx(Typography, { children: "Logs" }) }), _jsx(AccordionDetails, { children: messages && !isFetching && !isError && (_jsxs(List, { children: [messages.content.map(function (m) { return (_jsx(MessageListItem, { message: m, onClick: gotoMessage }, m.id)); }), _jsx(ListItemButton, { onClick: gotoMessages, children: _jsx(ListItemText, { primary: "See more logs" }) })] })) })] }));
}
