var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var STX = 0x02;
var queueSize = 2;
export var convertUint8_to_hexStr = function (uint8) {
    return Array.from(uint8)
        .map(function (i) { return i.toString(16).padStart(2, '0'); })
        .join(' ');
};
var parseTrxDResponsePacket = function (data) {
    if (data.length < 4) {
        return false;
    }
    if (data[0] !== STX) {
        console.error('Invalid payload: ', convertUint8_to_hexStr(data));
        throw new Error("Response frame missing STX (was ".concat(data[0], ")}"));
    }
    var flag = data[1];
    var length = data[2];
    if (data.length < length + 4) {
        return false;
    }
    var payload = data.slice(3, 3 + length);
    var checksum = payload[length + 3];
    // Verify checksum
    return {
        flag: flag,
        length: length,
        payload: payload,
        checksum: checksum,
        rest: data.slice(4 + length)
    };
};
var TrxDPacketReader = /** @class */ (function () {
    function TrxDPacketReader() {
        this.buffer = undefined;
    }
    TrxDPacketReader.prototype.getAllData = function (data) {
        if (this.buffer) {
            var copy = new Uint8Array(this.buffer);
            this.buffer = undefined;
            var tmp = new Uint8Array(copy.length + data.length);
            tmp.set(copy);
            tmp.set(data, copy.length);
            this.buffer = new Uint8Array(0);
            return tmp;
        }
        return new Uint8Array(data);
    };
    TrxDPacketReader.prototype.fetchPacket = function () {
        if (this.buffer) {
            var frame = parseTrxDResponsePacket(this.buffer);
            if (frame) {
                this.buffer = frame.rest;
                return frame;
            }
        }
        return undefined;
    };
    TrxDPacketReader.prototype.push = function (data) {
        var allData = this.getAllData(data);
        try {
            var frame = parseTrxDResponsePacket(allData);
            if (frame) {
                this.buffer = frame.rest;
                return frame;
            }
            else {
                this.buffer = allData;
            }
        }
        catch (e) {
            console.error('Failed to parse: ', e);
            console.error('Failed to parse: ', convertUint8_to_hexStr(allData));
        }
        return undefined;
    };
    return TrxDPacketReader;
}());
export { TrxDPacketReader };
var modula256CheckSum = function (data, len) {
    var sum = 0;
    for (var i = 0; i < len; i++) {
        sum += data[i];
    }
    return sum % 256;
};
export var makeTrxDPacketV1 = function (data) {
    var buffer = new ArrayBuffer(data.length + 4);
    var view = new Uint8Array(buffer);
    view[0] = STX;
    view[1] = 0x01;
    view[2] = data.length;
    view.set(data, 3);
    view[view.length - 1] = modula256CheckSum(view, view.length - 1);
    return view;
};
export var makeTrxDPacketV2 = function (data) {
    var buffer = new ArrayBuffer(data.length + 5);
    var view = new Uint8Array(buffer);
    view[0] = STX;
    view[1] = 0x02;
    view[2] = data.length >> 8;
    view[3] = data.length;
    view.set(data, 4);
    view[view.length - 1] = modula256CheckSum(view, view.length - 1);
    return view;
};
var TrxDClient = /** @class */ (function () {
    function TrxDClient() {
        this.port = undefined;
        this.reader = new TrxDPacketReader();
        this.log = [];
        this.pendingSize = 0;
        this.reading = false;
        this.writing = false;
        this.writeQueue = [];
        this.onLogUpdated = function () { };
    }
    TrxDClient.prototype.addLog = function (log) {
        this.log.push(log);
        this.onLogUpdated();
    };
    TrxDClient.prototype.sendPacket = function (data, onSent) {
        return __awaiter(this, void 0, void 0, function () {
            var request, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.port === undefined) {
                            throw new Error('Not connected');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        request = {
                            data: data.length > 250 ? makeTrxDPacketV2(data) : makeTrxDPacketV1(data),
                            onSent: onSent
                        };
                        this.writeQueue.push(request);
                        return [4 /*yield*/, this.flushQueue()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error('Error', e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TrxDClient.prototype.flushQueue = function () {
        return __awaiter(this, void 0, void 0, function () {
            var writer, data, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (this.writeQueue.length === 0 ||
                            this.writing ||
                            this.port === undefined) {
                            return [2 /*return*/];
                        }
                        this.writing = true;
                        writer = this.port.writable.getWriter();
                        _a.label = 1;
                    case 1:
                        if (!(this.writeQueue.length > 0 && this.pendingSize < queueSize)) return [3 /*break*/, 4];
                        data = this.writeQueue.shift();
                        if (!data) return [3 /*break*/, 3];
                        return [4 /*yield*/, writer.write(data.data)];
                    case 2:
                        _a.sent();
                        data.onSent(data.data);
                        this.pendingSize++;
                        _a.label = 3;
                    case 3: return [3 /*break*/, 1];
                    case 4:
                        writer.releaseLock();
                        this.writing = false;
                        return [3 /*break*/, 6];
                    case 5:
                        e_2 = _a.sent();
                        console.error('Error', e_2);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    TrxDClient.prototype.recvPackets = function (handler) {
        return __awaiter(this, void 0, void 0, function () {
            var frames, frame;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.pendingSize === 0) {
                            return [2 /*return*/, 0];
                        }
                        frames = 0;
                        _a.label = 1;
                    case 1:
                        if (!(this.pendingSize > 0)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.recvPacket()];
                    case 2:
                        frame = _a.sent();
                        if (!frame) return [3 /*break*/, 4];
                        handler(frame);
                        return [4 /*yield*/, this.flushQueue()];
                    case 3:
                        _a.sent();
                        frames++;
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/, -1];
                    case 5: return [3 /*break*/, 1];
                    case 6: return [2 /*return*/, frames];
                }
            });
        });
    };
    TrxDClient.prototype.recvPacket = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.readWithTimeout(2000)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TrxDClient.prototype.readWithTimeout = function (timeout) {
        return __awaiter(this, void 0, Promise, function () {
            var existingFrame, reader, timer, frame, value;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.port) {
                            throw new Error('Not connected');
                        }
                        existingFrame = this.reader.fetchPacket();
                        if (existingFrame) {
                            this.pendingSize--;
                            return [2 /*return*/, existingFrame];
                        }
                        if (this.reading) {
                            return [2 /*return*/, undefined];
                        }
                        this.reading = true;
                        reader = this.port.readable.getReader();
                        timer = setTimeout(function () {
                            _this.addLog('ERROR: Timeout reading response');
                            reader.releaseLock();
                            _this.reading = false;
                        }, timeout);
                        frame = undefined;
                        _a.label = 1;
                    case 1:
                        if (!(this.port.readable && !frame)) return [3 /*break*/, 3];
                        return [4 /*yield*/, reader.read()];
                    case 2:
                        value = (_a.sent()).value;
                        frame = this.reader.push(value);
                        return [3 /*break*/, 1];
                    case 3:
                        this.pendingSize--;
                        clearTimeout(timer);
                        reader.releaseLock();
                        this.reading = false;
                        return [2 /*return*/, frame];
                }
            });
        });
    };
    TrxDClient.prototype.connect = function (port) {
        return __awaiter(this, void 0, void 0, function () {
            var e_3, info;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.port) {
                            return [2 /*return*/];
                        }
                        this.port = port;
                        this.port.ondisconnect = function () {
                            _this.addLog('Disconnected');
                            _this.port = undefined;
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, port.open({
                                baudRate: 19200,
                                stopBits: 1,
                                dataBits: 8,
                                parity: 'none',
                                flowControl: 'none'
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        if (e_3.code === DOMException.INVALID_STATE_ERR) {
                            console.error('Port already open');
                            return [2 /*return*/];
                        }
                        console.error('Failed to open port', e_3);
                        this.port = undefined;
                        throw new Error('Failed to open port');
                    case 4:
                        info = port.getInfo();
                        this.addLog("Connected to: ".concat(info.usbProductId, ", ").concat(info.usbVendorId));
                        return [2 /*return*/];
                }
            });
        });
    };
    TrxDClient.prototype.disconnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.port === undefined) {
                            throw new Error('Not connected');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.port.close()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _a.sent();
                        console.error('Failed to close port', e_4);
                        return [3 /*break*/, 4];
                    case 4:
                        this.port = undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    TrxDClient.prototype.isConnected = function () {
        return this.port !== undefined;
    };
    return TrxDClient;
}());
export { TrxDClient };
