import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { usePopupMenu } from './PopupMenu';
export function PopupMenuItem(_a) {
    var children = _a.children, onClick = _a.onClick, onSelect = _a.onSelect, path = _a.path;
    var navigator = useNavigate();
    var onCloseMenu = usePopupMenu().onCloseMenu;
    var handleClick = function () {
        onCloseMenu();
        if (onClick) {
            onClick();
        }
        if (onSelect) {
            onSelect(true);
        }
        if (path) {
            navigator(path);
        }
    };
    return _jsx(MenuItem, { onClick: handleClick, children: children });
}
