import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import React from 'react';
var calculate = function (bytes, order) {
    var result = 0;
    var data = order ? bytes.slice().reverse() : bytes;
    for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
        var byte = data_1[_i];
        result = result * 256 + byte;
    }
    return result;
};
export function ExplainWord(_a) {
    var value = _a.value, endianness = _a.endianness;
    var result = calculate(value, endianness === 'little');
    var title = endianness === 'big' ? 'Big-endian' : 'Little-endian';
    return _jsx(TextField, { label: title, value: result, disabled: true });
}
