import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { PricerPagination } from '../../../../components/atoms/PricerPagination';
import { MessageListItem } from '../atoms/MessageListItem';
export function MessageList(_a) {
    var messages = _a.messages, page = _a.page, setPage = _a.setPage, gotoMessage = _a.gotoMessage;
    var onClick = function (message) {
        gotoMessage(message.id);
    };
    return (_jsxs(_Fragment, { children: [messages.content.map(function (m) { return (_jsx(MessageListItem, { message: m, onClick: onClick }, m.id)); }), _jsx(PricerPagination, { items: messages, page: page, setPage: setPage })] }));
}
