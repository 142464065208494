import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Grid, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { transmissionApi, useListLabelTypesQuery } from '../../../../api/api';
import { buildPageFilterQuery } from '../../../../common/helpers';
import { useQuery } from '../../../../common/hooks/query';
import { PricerPagination } from '../../../../components/atoms/PricerPagination';
import { AddButton } from '../../../../components/atoms/toolbar/AddButton';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
import { LabelTypeListItem } from '../atoms/LabelTypeListItem';
export function LabelTypeListView() {
    var navigator = useNavigate();
    var addError = useMessageHandler().addError;
    var dispatch = useAppDispatch();
    var query = useQuery();
    var page = parseInt(query.get('page') || '0');
    var filter = query.get('filter') || '';
    var _a = useListLabelTypesQuery({ page: page, size: 21, filter: filter }), labelTypes = _a.data, isFetching = _a.isFetching, isError = _a.isError, error = _a.error;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch label-types');
        }
    }, [isError, addError, error]);
    var onRefresh = function () {
        dispatch(transmissionApi.util.invalidateTags(['LabelTypes']));
    };
    var setFilter = function (filter) {
        if (filter === '') {
            navigator("/label-types/".concat(buildPageFilterQuery(0, filter)));
        }
        else {
            navigator("/label-types/".concat(buildPageFilterQuery(0, filter)));
        }
    };
    var setPage = function (page) {
        navigator("/label-types/".concat(buildPageFilterQuery(page, filter)));
    };
    var getId = function (l) {
        return "".concat(l.plType, "-").concat(l.hardwareRevision, "-").concat(l.softwareRevision);
    };
    return (_jsxs(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch label-types", hasPaper: false, toolbar: _jsxs(_Fragment, { children: [_jsx(TextField, { sx: { display: { xs: 'none', sm: 'none', md: 'block' } }, label: "Filter", variant: "outlined", size: "small", value: filter, onChange: function (e) { return setFilter(e.target.value); } }), _jsxs(ToggleButtonGroup, { sx: { display: { xs: 'none', sm: 'inline-block' } }, value: filter, exclusive: true, onChange: function (e, v) { return setFilter(v); }, "aria-label": "filter", children: [_jsx(ToggleButton, { value: "", "aria-label": "left aligned", children: _jsx(FilterAltOffIcon, {}) }), _jsx(ToggleButton, { value: "segment", "aria-label": "centered", children: "Segment" }), _jsx(ToggleButton, { value: "graphical", "aria-label": "right aligned", children: "Graphic" }), _jsx(ToggleButton, { sx: {
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'inline-block'
                                }
                            }, value: "=BWR", "aria-label": "justified", children: "3-color Red" }), _jsx(ToggleButton, { sx: {
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'inline-block'
                                }
                            }, value: "=BWY", "aria-label": "justified", children: "3-color Yellow" }), _jsx(ToggleButton, { sx: {
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'inline-block'
                                }
                            }, value: "=BWRY", "aria-label": "justified", children: "4-color" })] }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: onRefresh, isFetching: isFetching }), _jsx(AddButton, { path: "/label-types/add" })] }), children: [_jsx(Grid, { container: true, spacing: 2, children: (labelTypes === null || labelTypes === void 0 ? void 0 : labelTypes.content) &&
                    labelTypes.content.map(function (ap) { return (_jsx(LabelTypeListItem, { labelType: ap, onClick: function (ap) { return navigator("/label-types/".concat(ap.plType)); } }, getId(ap))); }) }), _jsx(PricerPagination, { items: labelTypes, page: page, setPage: setPage })] }));
}
