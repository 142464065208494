import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { relativeTime, toIsoDate } from '../../../../common/helpers';
import { MessageIcon } from '../../../Messages/components/atoms/MessageIcon';
import { humanStatus } from '../../../Messages/message';
export function MessageListItem(_a) {
    var message = _a.message, onClick = _a.onClick;
    var capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var duration = relativeTime(message.received, message.sent);
    var wasAcked = message.hasRequest && message.hasReply;
    var direction = message.hasRequest ? 'sent' : 'received';
    var inString = wasAcked ? " in ".concat(duration) : '';
    var receivedDate = message.received
        ? ", Received: ".concat(toIsoDate(message.received))
        : '';
    var tooltip = "Sent: ".concat(toIsoDate(message.sent)).concat(receivedDate, ", Status: ").concat(message.status);
    var status = humanStatus(message.status);
    var statusColor = message.status === 'success' || message.status === 'received'
        ? '#054816'
        : message.status === 'error'
            ? '#e53800'
            : '#3178d4';
    var title = "".concat(capitalize(message.type), " message ").concat(direction, " ").concat(status).concat(inString, ".");
    return (_jsxs(ListItemButton, { onClick: function () { return onClick(message.id); }, children: [_jsx(ListItemIcon, { sx: { color: statusColor }, children: _jsx(MessageIcon, { type: message.type, status: message.status }) }), _jsx(Tooltip, { title: tooltip, children: _jsx(ListItemText, { primary: title, secondary: relativeTime(message.sent) }) })] }));
}
