var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '../../../../common/assets/github-markdown.css';
import { CircularProgress, Stack, Switch, Typography } from '@mui/material';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useExplainRigadoZipMutation } from '../../../../api/api';
import { FileUpload, readUploadFile } from '../../../../components/atoms/form/FileUpload';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
export function ExplainRigadoLogView() {
    var _this = this;
    var _a = useMessageHandler(), addError = _a.addError, addApiError = _a.addApiError;
    var _b = React.useState(''), target = _b[0], setTarget = _b[1];
    var _c = React.useState(''), title = _c[0], setTitle = _c[1];
    var explain = useExplainRigadoZipMutation()[0];
    var _d = React.useState(false), busy = _d[0], setBusy = _d[1];
    var _e = React.useState(['commands']), include = _e[0], setInclude = _e[1];
    var doUpload = function (selectedFile) { return __awaiter(_this, void 0, void 0, function () {
        var log, _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!selectedFile) {
                        addError('No file selected');
                        return [2 /*return*/, false];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    setBusy(true);
                    setTitle(selectedFile.name);
                    return [4 /*yield*/, readUploadFile(selectedFile)];
                case 2:
                    log = _b.sent();
                    _a = setTarget;
                    return [4 /*yield*/, explain({ data: log, include: include.join(',') }).unwrap()];
                case 3:
                    _a.apply(void 0, [_b.sent()]);
                    setBusy(false);
                    return [2 /*return*/, true];
                case 4:
                    e_1 = _b.sent();
                    addApiError('Failed to upload log', e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/, false];
            }
        });
    }); };
    return (_jsx(SimplePageContent, { toolbar: _jsx(_Fragment, { children: _jsx(BackButton, { path: "/tools" }) }), children: _jsxs(FormArea, { title: 'Format logs', children: [_jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(Switch, { checked: include.includes('junk'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['junk'], false) : e.filter(function (i) { return i !== 'junk'; });
                                });
                            } }), "\u00A0Junk", _jsx(Switch, { checked: include.includes('commands'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['commands'], false) : e.filter(function (i) { return i !== 'commands'; });
                                });
                            } }), "\u00A0Commands", _jsx(Switch, { checked: include.includes('debug'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['debug'], false) : e.filter(function (i) { return i !== 'debug'; });
                                });
                            } }), "\u00A0Debug", _jsx(Switch, { checked: include.includes('info'), onChange: function (e, check) {
                                return setInclude(function (e) {
                                    return check ? __spreadArray(__spreadArray([], e, true), ['info'], false) : e.filter(function (i) { return i !== 'info'; });
                                });
                            } }), "\u00A0Info"] }), _jsx(FileUpload, { id: "rigado-logs", title: "Click to upload log gz file", onUpload: function (files) { return doUpload(files[0]); } }), busy && _jsx(CircularProgress, { size: "2em" }), !busy && target && (_jsxs(Typography, { variant: "h5", children: ["Showing log from: ", title] })), !busy && (_jsx("article", { className: "markdown-body", children: _jsx(ReactMarkdown, { children: target, remarkPlugins: [remarkGfm] }) }))] }) }));
}
