var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { authSlice, getRole, parseToken } from '../authSlice';
import { ROLE_MAPPING } from '../permissions';
var mapPermissions = function (permissions) {
    var result = {};
    for (var role in permissions) {
        for (var _i = 0, _a = permissions[role]; _i < _a.length; _i++) {
            var permission = _a[_i];
            if (!result[permission]) {
                result[permission] = [];
            }
            result[permission].push(role);
        }
    }
    return result;
};
var PERMISSION_MAP = mapPermissions(ROLE_MAPPING);
export var useAuthentication = function () {
    var _a = useAuth0(), getAccessTokenSilently = _a.getAccessTokenSilently, isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, error = _a.error;
    var auth = useAppSelector(function (store) { return store.auth; });
    var dispatch = useAppDispatch();
    var getActualRole = function (role) {
        return role.startsWith('pricer-') ? role.slice(7) : role;
    };
    var elevate = function (role) { return __awaiter(void 0, void 0, void 0, function () {
        var token, resultingRole;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        cacheMode: 'off',
                        authorizationParams: {
                            pricerTenantId: auth.tenantId,
                            pricerRole: role
                        }
                    })];
                case 1:
                    token = _a.sent();
                    resultingRole = getRole(parseToken(token));
                    if (resultingRole !== role) {
                        throw new Error('Failed to elevate role');
                    }
                    dispatch(authSlice.actions.addToken(token));
                    return [2 /*return*/];
            }
        });
    }); };
    var toggleElevation = function (baseRole, elevatedRole) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(getActualRole(auth.role) === elevatedRole)) return [3 /*break*/, 2];
                    return [4 /*yield*/, elevate(baseRole)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, elevate(elevatedRole)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var switchTenant = function (tenantId_1) {
        var args_1 = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args_1[_i - 1] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([tenantId_1], args_1, true), void 0, function (tenantId, role) {
            var actualRole, token;
            if (role === void 0) { role = auth.role; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        actualRole = getActualRole(role);
                        return [4 /*yield*/, getAccessTokenSilently({
                                cacheMode: 'off',
                                authorizationParams: {
                                    pricerTenantId: tenantId,
                                    pricerRole: actualRole
                                }
                            })];
                    case 1:
                        token = _a.sent();
                        dispatch(authSlice.actions.addToken(token));
                        return [2 /*return*/];
                }
            });
        });
    };
    var returnParentTenant = function () { return __awaiter(void 0, void 0, void 0, function () {
        var actualRole, token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (auth.parentTenant === auth.tenantId) {
                        return [2 /*return*/];
                    }
                    actualRole = getActualRole(auth.role);
                    return [4 /*yield*/, getAccessTokenSilently({
                            cacheMode: 'off',
                            authorizationParams: {
                                pricerTenantId: auth.parentTenant,
                                pricerRole: actualRole
                            }
                        })];
                case 1:
                    token = _a.sent();
                    dispatch(authSlice.actions.addToken(token));
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        cacheMode: 'off'
                    })];
                case 1:
                    token = _a.sent();
                    dispatch(authSlice.actions.addToken(token));
                    return [2 /*return*/];
            }
        });
    }); };
    var hasPermission = function (permission) {
        var map = PERMISSION_MAP[permission] || [];
        return map.includes(auth.role);
    };
    var hasAnyPermission = function (permission) {
        for (var _i = 0, permission_1 = permission; _i < permission_1.length; _i++) {
            var p = permission_1[_i];
            if (hasPermission(p)) {
                return true;
            }
        }
        return false;
    };
    return {
        hasToken: isAuthenticated && auth.token !== undefined && auth.token !== '',
        token: auth.token,
        auth0Error: error,
        isLoading: isLoading,
        isAuthenticated: isAuthenticated,
        fetchToken: fetchToken,
        has: hasPermission,
        hasAny: hasAnyPermission,
        elevate: elevate,
        toggleElevation: toggleElevation,
        switchTenant: switchTenant,
        returnParentTenant: returnParentTenant,
        role: getActualRole(auth.role),
        tenant: auth.tenantId,
        parentTenant: auth.parentTenant
    };
};
