var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'usehooks-ts';
import { useGetLinkForLabelQuery, useLinkItemMutation, useUnLinkItemMutation } from '../../../../api/itemApi';
import { useMessageHandler } from '../../../../context/MessageContext';
export function Links(_a) {
    var _this = this;
    var label = _a.label;
    var _b = React.useState(''), itemId = _b[0], setItemId = _b[1];
    var navigator = useNavigate();
    var addApiError = useMessageHandler().addApiError;
    var _c = useLocalStorage('label.show.link', false), expanded = _c[0], setExpanded = _c[1];
    var linkItem = useLinkItemMutation()[0];
    var unLinkItem = useUnLinkItemMutation()[0];
    var links = useGetLinkForLabelQuery(label.barcode).data;
    var onLink = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, linkItem({ itemId: itemId, barcode: label.barcode }).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to link label", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onUnLink = function (link) { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, unLinkItem(link).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    addApiError("Failed to unlink ".concat(link.barcode), e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Accordion, { expanded: expanded, onChange: function (e, isExpanded) { return setExpanded(isExpanded); }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "icons-content", id: "icons-header", children: _jsx(Typography, { children: "Links" }) }), _jsxs(AccordionDetails, { children: [_jsx(List, { children: links &&
                            links.map(function (link) { return (_jsx(ListItem, { onClick: function () { return navigator("/items/".concat(link.itemId)); }, secondaryAction: _jsx(IconButton, { edge: "end", "aria-label": "delete", onClick: function () { return onUnLink(link); }, children: _jsx(DeleteIcon, {}) }), disablePadding: true, children: _jsxs(ListItemButton, { role: undefined, dense: true, children: [_jsx(ListItemIcon, { children: _jsx(VideoLabelIcon, {}) }), _jsx(ListItemText, { primary: link.itemId })] }, link.barcode) }, link.itemId)); }) }), _jsxs(Stack, { direction: "row", spacing: 3, children: [_jsx(TextField, { size: "small", label: "Item id", variant: "outlined", value: itemId, onChange: function (e) { return setItemId(e.target.value); } }), _jsx(Button, { size: "small", variant: "outlined", color: "secondary", onClick: onLink, children: _jsx(AddIcon, {}) })] })] })] }));
}
