import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AppsIcon from '@mui/icons-material/Apps';
import CategoryIcon from '@mui/icons-material/Category';
import ConstructionIcon from '@mui/icons-material/Construction';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RouterIcon from '@mui/icons-material/Router';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { AppBar, Tab } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { ModuleSelector } from './ModuleSelectorTab.styles';
export function ModuleSelectorTab(_a) {
    var selected = _a.selected;
    var navigator = useNavigate();
    return (_jsx(AppBar, { position: "relative", elevation: 0, sx: { backgroundColor: '#e1e9e3' }, children: _jsxs(ModuleSelector, { variant: "scrollable", scrollButtons: "auto", value: selected, onChange: function (event, newValue) { return navigator(newValue); }, children: [_jsx(Tab, { value: "/labels", iconPosition: "start", icon: _jsx(VideoLabelIcon, {}), label: "Labels" }), _jsx(Tab, { value: "/messages", iconPosition: "start", icon: _jsx(MailOutlineIcon, {}), label: "Messages" }), _jsx(Tab, { value: "/access-points", iconPosition: "start", icon: _jsx(RouterIcon, {}), label: "Access Points" }), _jsx(Tab, { value: "/zones", iconPosition: "start", icon: _jsx(AppsIcon, {}), label: "Zones" }), _jsx(Tab, { value: "/label-types", iconPosition: "start", icon: _jsx(CategoryIcon, {}), label: "Label types" }), _jsx(Tab, { value: "/items", iconPosition: "start", icon: _jsx(CategoryIcon, {}), label: "Items" }), _jsx(Tab, { value: "/tools", iconPosition: "start", icon: _jsx(ConstructionIcon, {}), label: "Tools" })] }) }));
}
