import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
export var LoginWrapper = styled(Box)(function () { return ({
    paddingLeft: '40px',
    minHeight: 'calc(100vh - 60px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}); });
export var InputWrapper = styled(Box)(function () { return ({
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    alignSelf: 'left',
    width: '400px'
}); });
export var LoginButton = styled(Button)(function () { return ({
    '&.MuiButtonBase-root': {
        textTransform: 'initial',
        color: 'white',
        fontSize: '18px',
        borderRadius: '36px',
        letterSpacing: 'initial',
        padding: '10px 35px',
        boxShadow: '0 0 0 0',
        marginBottom: '8px'
    },
    '&:not(.Mui-disabled).MuiButton-containedPrimary': {
        backgroundColor: '#6FCA84'
    }
}); });
