var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var INBOUND = [
    'info',
    'written',
    'response',
    'objectWritten',
    'configured',
    'unassociated',
    'events'
];
export var OUTBOUND = [
    'write',
    'command',
    'writeObject',
    'configure',
    'unassociate',
    'getAll',
    'unassociateAll',
    'setPawrParmeters'
];
export var ALL_TOPICS = __spreadArray(__spreadArray([], INBOUND, true), OUTBOUND, true);
