var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextureIcon from '@mui/icons-material/Texture';
import TimerIcon from '@mui/icons-material/Timer';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useSwitchPageMutation, useUploadTestImageMutation } from '../../../../api/labelApi';
import { getConfig } from '../../../../common/config';
import { useAuthentication } from '../../../../common/hooks/authentication';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
import { UploadImage } from './UploadImage';
import { UploadPattern } from './UploadPattern';
export function Pages(_a) {
    var _this = this;
    var _b;
    var label = _a.label, target = _a.target;
    var token = useAuthentication().token;
    var add = usePendingContext().add;
    var addApiError = useMessageHandler().addApiError;
    var _c = useLocalStorage('label.show.pages', false), expanded = _c[0], setExpanded = _c[1];
    var _d = useState(8), duration = _d[0], setDuration = _d[1];
    var _f = React.useState(undefined), open = _f[0], setOpen = _f[1];
    var _g = React.useState(false), showUpload = _g[0], setShowUpload = _g[1];
    var _h = React.useState(false), showPattern = _h[0], setShowPattern = _h[1];
    var _j = React.useState(0), lastPage = _j[0], setLastPage = _j[1];
    var _k = React.useState(0), hash = _k[0], setHash = _k[1];
    var switchPage = useSwitchPageMutation()[0];
    var uploadPattern = useUploadTestImageMutation()[0];
    var pageCount = ((_b = label.display) === null || _b === void 0 ? void 0 : _b.pages) || 1;
    var pages = __spreadArray([], Array(pageCount), true).map(function (_e, i) { return i; });
    var previewUrl = function (page) {
        return "".concat(getConfig().apiBase, "/transmission/api/v1/labels/").concat(label.barcode, "/image/").concat(page, "?access_token=").concat(token, "&hash=").concat(hash);
    };
    var onImageUploaded = function (changed) {
        if (changed) {
            setHash(hash + 1);
        }
        setShowUpload(false);
        setShowPattern(false);
    };
    var currentPage = (label.currentPage || 0) + 1;
    var onSwitchPage = function (page, duration) { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, switchPage({
                            barcode: label.barcode,
                            page: page,
                            duration: duration,
                            target: target
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    setOpen(undefined);
                    add(requestId, 'SWITCH-PAGE', label.barcode);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to switch page", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onReUpload = function (page) { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, uploadPattern({
                            barcode: label.barcode,
                            target: target,
                            pattern: 're-upload',
                            page: page,
                            compression: 'auto',
                            overlayBarcode: false
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'TEST_PATTERN', label.barcode);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    addApiError("Failed to switch page", e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onShowUpload = function (page) {
        setLastPage(page);
        setShowUpload(true);
    };
    var onShowPattern = function (page) {
        setLastPage(page);
        setShowPattern(true);
    };
    var theme = useTheme();
    var matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    var matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    var cols = matchDownMd ? (matchDownSm ? 1 : 2) : 3;
    return (_jsxs(Accordion, { expanded: expanded, onChange: function (e, isExpanded) { return setExpanded(isExpanded); }, children: [_jsxs(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "icons-content", id: "icons-header", children: [_jsx(Typography, { sx: { width: '33%', flexShrink: 0 }, children: "Pages" }), _jsxs(Typography, { sx: { display: { xs: 'none', sm: 'block' }, color: 'text.secondary' }, children: ["Current page: ", currentPage] })] }), _jsxs(AccordionDetails, { children: [_jsx(UploadImage, { label: label, target: target, page: lastPage, open: showUpload, onClose: onImageUploaded }), _jsx(UploadPattern, { label: label, target: target, page: lastPage, open: showPattern, onClose: onImageUploaded }), _jsxs(Dialog, { open: open !== undefined, children: [_jsx(DialogTitle, { children: "How long do you want to show this page" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: _jsx(TextField, { label: "Duration", value: duration, onChange: function (e) { return setDuration(+e.target.value); } }) }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return setOpen(undefined); }, children: "Cancel" }), _jsx(PricerButton, { type: "ok", onClick: function () { return onSwitchPage(open || 0, duration); }, children: "Switch" })] })] }), _jsx(ImageList, { cols: cols, children: pages.map(function (page) { return (_jsxs(ImageListItem, { children: [_jsx("img", { src: previewUrl(page), alt: "Page: ".concat(page + 1), loading: "lazy" }), _jsx(ImageListItemBar, { position: "top", title: label.currentPage === page
                                        ? 'Current page'
                                        : "Page: ".concat(page + 1), actionIcon: _jsxs(_Fragment, { children: [_jsx(IconButton, { sx: { color: 'white' }, onClick: function () { return onShowUpload(page); }, children: _jsx(FileUploadIcon, {}) }), _jsx(IconButton, { sx: { color: 'white' }, onClick: function () { return onShowPattern(page); }, children: _jsx(TextureIcon, {}) }), label.currentPage !== page && (_jsx(IconButton, { sx: { color: 'white' }, onClick: function () { return setOpen(page); }, children: _jsx(TimerIcon, {}) })), _jsx(IconButton, { sx: { color: 'white' }, onClick: function () { return onReUpload(page); }, children: _jsx(RefreshIcon, {}) }), label.currentPage !== page && (_jsx(IconButton, { sx: { color: 'white' }, onClick: function () { return onSwitchPage(page, 0); }, children: _jsx(CastConnectedIcon, {}) }))] }) })] }, "page-".concat(page))); }) }), _jsxs(Stack, { spacing: 3, direction: "row", children: [_jsx(PricerButton, { type: label.currentPage === 13 ? 'ok' : 'other', onClick: function () { return onSwitchPage(13, 0); }, children: "Show status page" }), _jsx(PricerButton, { type: label.currentPage === 14 ? 'ok' : 'other', onClick: function () { return onSwitchPage(14, 0); }, children: "Show barcode" })] })] })] }));
}
