var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { transmissionApi, useGetMetricsQuery } from '../../../../api/api';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
var renderTags = function (tags) {
    return Object.keys(tags)
        .map(function (key) { return "".concat(key, "=").concat(tags[key]); })
        .join(', ');
};
export function StatsView() {
    var addError = useMessageHandler().addError;
    var dispatch = useAppDispatch();
    var _a = React.useState(undefined), filter = _a[0], setFilter = _a[1];
    var _b = useGetMetricsQuery(), data = _b.data, isError = _b.isError, error = _b.error, isFetching = _b.isFetching;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch metrics: Unknown error');
        }
    }, [isError, addError, error]);
    var refreshApi = function () {
        dispatch(transmissionApi.util.invalidateTags(['Metrics']));
    };
    var metrics = data
        ? data.map(function (e) { return (__assign(__assign({}, e), { rTags: renderTags(e.tags) })); })
        : [];
    var allKeys = metrics.map(function (m) { return m.key.split('.')[0]; });
    var uniqueKeys = allKeys.filter(function (v, i, a) { return a.indexOf(v) === i; });
    var filteredMetrics = filter
        ? metrics.filter(function (m) { return m.key.startsWith(filter); })
        : metrics;
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch message", toolbar: _jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/tools" }), _jsxs(ToggleButtonGroup, { value: filter, exclusive: true, onChange: function (e, v) { return setFilter(v); }, "aria-label": "filter", children: [_jsx(ToggleButton, { value: "", children: _jsx(FilterAltOffIcon, {}) }), uniqueKeys.map(function (key) { return (_jsx(ToggleButton, { value: key, children: key }, key)); })] }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching })] }), children: _jsx(TableContainer, { children: _jsxs(Table, { sx: { minWidth: 650 }, "aria-label": "simple table", children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Metric" }), _jsx(TableCell, { children: "Tags" }), _jsx(TableCell, { align: "right", children: "Value" })] }) }), _jsx(TableBody, { children: filteredMetrics.map(function (m) { return (_jsxs(TableRow, { sx: { '&:last-child td, &:last-child th': { border: 0 } }, children: [_jsx(TableCell, { component: "th", scope: "row", children: m.key }), _jsx(TableCell, { children: m.rTags }), _jsx(TableCell, { align: "right", children: m.value })] }, "".concat(m.key, "-").concat(m.rTags))); }) })] }) }) }));
}
