var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import { labelApi } from '../api/labelApi';
import { useAppDispatch } from '../store/store';
import { useMessageHandler } from './MessageContext';
var PendingContext = React.createContext({});
export var usePendingContext = function () {
    return useContext(PendingContext);
};
export var PendingContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), messages = _b[0], setMessages = _b[1];
    var _c = useState({}), subscriptions = _c[0], setSubscriptions = _c[1];
    var stompClient = useStompClient();
    var dispatch = useAppDispatch();
    var addError = useMessageHandler().addError;
    var add = useCallback(function (requestId, type, barcode) {
        setMessages(function (current) { return __spreadArray(__spreadArray([], current, true), [{ requestId: requestId, type: type, barcode: barcode }], false); });
        if (stompClient == null) {
            console.log('Not connected, adding offline message', stompClient);
            // TODO: Add error message
            return;
        }
        stompClient.publish({
            destination: '/app/pending',
            body: JSON.stringify({ requestId: requestId })
        });
    }, [stompClient]);
    var remove = useCallback(function (requestId) {
        setMessages(messages.filter(function (m) { return m.requestId !== requestId; }));
    }, [messages]);
    var has = useCallback(function () { return messages.length > 0; }, [messages]);
    var get = useCallback(function () { return messages; }, [messages]);
    var subscribe = useCallback(function (requestId, handler) {
        setSubscriptions(function (subs) {
            subs[requestId] = handler;
            return subs;
        });
    }, []);
    var value = useMemo(function () { return ({ add: add, has: has, get: get, subscribe: subscribe }); }, [add, has, get, subscribe]);
    var onMessage = useCallback(function (message) {
        var msg = JSON.parse(message.body);
        remove(msg.requestId);
        var handler = subscriptions[msg.requestId];
        if (handler !== undefined) {
            handler(msg);
            setSubscriptions(function (subs) {
                delete subs[msg.requestId];
                return subs;
            });
        }
        if (!msg.ack.ack) {
            addError("Failed to ".concat(msg.action, " ").concat(msg.barcode, ": ").concat(msg.reason));
        }
        dispatch(labelApi.util.invalidateTags(['Labels', 'Label']));
    }, [remove, dispatch, subscriptions, addError]);
    useSubscription('/user/queue/pending', onMessage);
    return (_jsx(PendingContext.Provider, { value: value, children: children }));
};
