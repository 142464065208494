import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router';
import { useListMessagesQuery } from '../../../../api/api';
import { buildPageFilterQuery } from '../../../../common/helpers';
import { useQuery } from '../../../../common/hooks/query';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { MessageToolbar } from '../molecules/MessageToolbar';
import { MessageList } from '../organisms/MessageList';
export function MessageListView() {
    var navigator = useNavigate();
    var query = useQuery();
    var page = parseInt(query.get('page') || '0');
    var filter = query.get('filter') || '';
    var onSetFilter = function (filter) {
        if (filter === '') {
            navigator("/messages/".concat(buildPageFilterQuery(0, filter)));
        }
        else {
            navigator("/messages/".concat(buildPageFilterQuery(0, filter)));
        }
    };
    var onSetPage = function (page) {
        navigator("/messages/".concat(buildPageFilterQuery(page, filter)));
    };
    var gotoMessage = function (id) {
        navigator("/messages/".concat(id).concat(buildPageFilterQuery(page, filter)));
    };
    var _a = useListMessagesQuery({ page: page, filter: filter }), messages = _a.data, isFetching = _a.isFetching, isError = _a.isError, error = _a.error;
    return (_jsx(SimplePageContent, { isError: isError, apiError: error, errorMessage: "Failed to fetch messages", hasPaper: false, toolbar: _jsx(MessageToolbar, { filter: filter, onSetFilter: onSetFilter, isFetching: isFetching }), children: messages && (_jsx(MessageList, { messages: messages, gotoMessage: gotoMessage, page: page, setPage: onSetPage })) }));
}
