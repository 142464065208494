import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import { Card, CardActionArea, CardContent, CardHeader, Chip, Typography } from '@mui/material';
import React from 'react';
import { relativeTime } from '../../../../common/helpers';
export function AccessPointListItem(_a) {
    var accessPoint = _a.accessPoint, onClick = _a.onClick;
    var statusColor = accessPoint.online ? 'primary' : 'warning';
    var status = accessPoint.online ? 'online' : 'offline';
    var capitalize = function (str) {
        return str === undefined || str.length === 0
            ? 'Unknown'
            : str.charAt(0).toUpperCase() + str.slice(1);
    };
    var isIr = accessPoint.type === 'ir';
    var isRadio = accessPoint.type === 'radio';
    var icon = accessPoint.online || isRadio ? (_jsx(WifiTetheringIcon, { sx: { color: '#054816' } })) : (_jsx(WifiTetheringOffIcon, { sx: { color: '#e53800' } }));
    var title = accessPoint.name
        ? "".concat(accessPoint.name, " (").concat(accessPoint.id, ")")
        : accessPoint.id;
    return (_jsx(Card, { sx: { minWidth: { sm: 1 / 2, md: 2 / 3 } }, children: _jsxs(CardActionArea, { onClick: function () { return onClick(accessPoint); }, children: [_jsx(CardHeader, { avatar: icon, title: title, subheader: relativeTime(accessPoint.seen) }), _jsx(CardContent, { children: _jsxs(Typography, { component: "div", variant: "body1", children: [capitalize(accessPoint.type), " access point ", accessPoint.model, " with hardware id ", accessPoint.id, isIr && (_jsx(Chip, { label: status, size: "small", color: statusColor, variant: "outlined" })), "."] }) })] }) }, accessPoint.id));
}
