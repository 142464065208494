var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, Paper, Switch, TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSaveLabelTypeMutation } from '../../../../api/api';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { OkCancelArea } from '../../../../components/atoms/form/OkCancelArea';
import { useMessageHandler } from '../../../../context/MessageContext';
export function AddLabelVersionView() {
    var _this = this;
    var navigator = useNavigate();
    var addApiError = useMessageHandler().addApiError;
    var _a = React.useState({
        display: { size: {} }
    }), labelType = _a[0], setLabelType = _a[1];
    var saveLabelType = useSaveLabelTypeMutation()[0];
    var onSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveLabelType(__assign({}, labelType)).unwrap()];
                case 1:
                    _a.sent();
                    navigator('/label-types');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to save ".concat(labelType.plType), e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Paper, { variant: "outlined", children: _jsxs(FormArea, { title: "Add label type", children: [_jsx(TextField, { label: "Label type ID", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.plType, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { plType: +e.target.value })); });
                    }, autoFocus: true }), _jsx(TextField, { label: "Hardware id", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.hardwareRevision, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { hardwareRevision: +e.target.value })); });
                    }, autoFocus: true }), _jsx(TextField, { label: "Software id", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.softwareRevision, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { softwareRevision: +e.target.value })); });
                    }, autoFocus: true }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: (labelType === null || labelType === void 0 ? void 0 : labelType.type) === 'graphical', onChange: function (e, check) {
                            return setLabelType(function (lt) { return (__assign(__assign({}, lt), { type: check ? 'graphical' : 'segment' })); });
                        } }), label: " Graphical" }), _jsx(TextField, { label: "Name", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.name, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { name: e.target.value })); });
                    }, autoFocus: true }), _jsx(TextField, { label: "Short name", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.shortName, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { shortName: e.target.value })); });
                    }, autoFocus: true }), _jsx(TextField, { label: "Product codes (comma separated)", variant: "outlined", value: ((labelType === null || labelType === void 0 ? void 0 : labelType.productNumber) || []).join(','), onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { productNumber: e.target.value.split(',') })); });
                    }, autoFocus: true }), _jsx(TextField, { label: "Display width", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.display.size.width, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { display: __assign(__assign({}, lt.display), { size: __assign(__assign({}, lt.display.size), { width: +e.target.value }) }) })); });
                    } }), _jsx(TextField, { label: "Display height", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.display.size.height, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { display: __assign(__assign({}, lt.display), { size: __assign(__assign({}, lt.display.size), { height: +e.target.value }) }) })); });
                    } }), _jsx(TextField, { label: "Display colors", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.display.colors, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { display: __assign(__assign({}, lt.display), { colors: e.target.value }) })); });
                    } }), _jsx(TextField, { label: "Number of pages", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.numberOfPages, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { numberOfPages: +e.target.value })); });
                    } }), _jsx(TextField, { label: "LED colors", variant: "outlined", value: labelType === null || labelType === void 0 ? void 0 : labelType.attentionLed, onChange: function (e) {
                        return setLabelType(function (lt) { return (__assign(__assign({}, lt), { attentionLed: e.target.value })); });
                    } }), _jsx(OkCancelArea, { onCancel: function () { return navigator('/label-types'); }, onOk: onSave, okTitle: "Add" })] }) }));
}
