var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useMemo, useState } from 'react';
var AppEventsContext = React.createContext({});
export var useAppEventContext = function () {
    return useContext(AppEventsContext);
};
export var AppEventsContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), eventHandlers = _b[0], setEventHandlers = _b[1];
    var onAppEvent = useCallback(function (handler) {
        setEventHandlers(function (existing) { return __spreadArray(__spreadArray([], existing, true), [handler], false); });
    }, []);
    var sendEvent = useCallback(function (event) {
        eventHandlers.forEach(function (handler) { return handler(event); });
    }, [eventHandlers]);
    var value = useMemo(function () { return ({
        onAppEvent: onAppEvent,
        sendEvent: sendEvent
    }); }, [onAppEvent, sendEvent]);
    return (_jsx(AppEventsContext.Provider, { value: value, children: children }));
};
