var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { fromByteArray, toByteArray } from 'base64-js';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useStompClient, useSubscription } from 'react-stomp-hooks';
import { useLocalStorage } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';
import { convertUint8_to_hexStr, TrxDClient } from '../views/IR/ProtocolReader';
import { useMessageHandler } from './MessageContext';
var IRBasestationContext = React.createContext({});
export var useIRBasestationContext = function () {
    return useContext(IRBasestationContext);
};
var ir = new TrxDClient();
var getTitle = function (p) {
    var isIr = p.payload[1] === 0x01;
    var title = jobs[p.payload[2]];
    var ack = isIr && p.payload.length > 6 ? p.payload[6] === 0x80 : false;
    var str = isIr && p.payload.length > 6 ? p.payload[7] : 0;
    var ackStr = ack ? ": ACK: ".concat(str) : isIr ? ': NACK' : '';
    var status = p.payload[3] !== 0 ? 'Error' : isIr ? ackStr : "Ok";
    return ('< ' + title + ' ' + status + '  -- ' + convertUint8_to_hexStr(p.payload));
};
var jobs = {};
export var IRBasestationContextProvider = function (_a) {
    var children = _a.children;
    var addError = useMessageHandler().addError;
    var _b = useState(false), isConnected = _b[0], setIsConnected = _b[1];
    var _c = useState(false), isIrConnected = _c[0], setIsIrConnected = _c[1];
    var _d = useState(false), hasIr = _d[0], setHasIr = _d[1];
    var stompClient = useStompClient();
    var _e = useLocalStorage('basestation-name', ''), name = _e[0], setName = _e[1];
    var clientId = useState(uuidv4())[0];
    var _f = useState([]), log = _f[0], setLog = _f[1];
    var _g = useState(undefined), timer = _g[0], setTimer = _g[1];
    var _h = useLocalStorage('basestation-config', {
        fastFel: false,
        wakeup: 3000,
        timeout: 3
    }), config = _h[0], setConfigState = _h[1];
    var logMessage = useCallback(function (message) {
        var date = new Date().toISOString().slice(11, 23);
        setLog(function (currentLog) { return __spreadArray(["".concat(date, " ").concat(message)], currentLog.slice(0, 200), true); });
    }, []);
    useEffect(function () {
        if (stompClient !== undefined && timer !== undefined) {
            setIsConnected(true);
            clearTimeout(timer);
            setTimer(undefined);
            if (!ir.isConnected()) {
                setIsIrConnected(false);
                return;
            }
            logMessage('! Reconnecting');
            stompClient.publish({
                destination: '/app/connect',
                body: JSON.stringify({ clientId: clientId, name: name })
            });
        }
        if (stompClient !== undefined && !isConnected) {
            setIsConnected(true);
            setIsIrConnected(ir.isConnected());
        }
        if (stompClient === undefined && isConnected) {
            if (timer === undefined) {
                setTimer(setTimeout(function () {
                    if (ir.isConnected()) {
                        ir.disconnect().then();
                    }
                    setIsIrConnected(false);
                    setIsConnected(false);
                }, 5000));
            }
        }
    }, [stompClient, isConnected, timer, clientId, logMessage, name]);
    useEffect(function () {
        if (hasIr) {
            return;
        }
        setHasIr(navigator.serial !== undefined);
    }, [hasIr]);
    useSubscription("/user/queue/send/".concat(clientId), function (message) {
        var msg = JSON.parse(message.body);
        var data = toByteArray(msg.payload);
        if (data.length > 3) {
            jobs[data[2]] = msg.title;
        }
        if (ir === undefined || !ir.isConnected()) {
            console.error('Sending while not connected');
            setIsIrConnected(false);
            return;
        }
        ir.sendPacket(data, function (data) {
            return logMessage('> ' + msg.title + '  -- ' + convertUint8_to_hexStr(data));
        }).then(function () {
            ir.recvPackets(function (r) {
                logMessage(getTitle(r));
                if (stompClient === undefined || stompClient === null) {
                    logMessage('! Websocket disconnected');
                    return;
                }
                stompClient.publish({
                    destination: '/app/recv',
                    body: JSON.stringify({ clientId: clientId, payload: fromByteArray(r.payload) })
                });
            }).then(function (responseCount) {
                if (responseCount === -1) {
                    return;
                }
                if (responseCount === 0) {
                    logMessage('! ' + msg.title + ' No data received');
                    return;
                }
            });
        });
    });
    useSubscription("/user/queue/replies", function (message) {
        var msg = JSON.parse(message.body);
        if (!msg.success) {
            addError(msg.error);
        }
    });
    var getPorts = useCallback(function () { return __awaiter(void 0, void 0, Promise, function () {
        var serial;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    serial = navigator.serial;
                    if (!serial) {
                        addError('Web serial not supported');
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, serial.getPorts()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, [addError]);
    var setConfig = useCallback(function (config) {
        if (stompClient === undefined) {
            addError('Failed to connect to web socket');
            return;
        }
        stompClient.publish({
            destination: '/app/configure',
            body: JSON.stringify(__assign(__assign({}, config), { clientId: clientId }))
        });
        setConfigState(config);
    }, [stompClient, addError, clientId, setConfigState]);
    var connect = useCallback(function (portId) { return __awaiter(void 0, void 0, void 0, function () {
        var serial, port, ports, filters, e_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    serial = navigator.serial;
                    if (!serial) {
                        addError('Web serial not supported');
                        return [2 /*return*/];
                    }
                    if (ir.isConnected()) {
                        console.error('Already connected');
                        return [2 /*return*/];
                    }
                    port = undefined;
                    return [4 /*yield*/, serial.getPorts()];
                case 1:
                    ports = _a.sent();
                    if (!(portId !== -1 && portId < ports.length)) return [3 /*break*/, 2];
                    port = ports[portId];
                    return [3 /*break*/, 5];
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    filters = [{ usbVendorId: 0x0403, usbProductId: 0x6015 }];
                    return [4 /*yield*/, serial.requestPort({ filters: filters })];
                case 3:
                    port = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    addError('Failed to open port: ' + e_1.message);
                    return [2 /*return*/];
                case 5:
                    if (!port) {
                        addError('No port selected');
                        return [2 /*return*/];
                    }
                    _a.label = 6;
                case 6:
                    _a.trys.push([6, 8, , 9]);
                    return [4 /*yield*/, ir.connect(port)];
                case 7:
                    _a.sent();
                    setIsIrConnected(ir.isConnected());
                    logMessage('- Connected to usb');
                    if (stompClient === undefined) {
                        setIsConnected(false);
                        addError('Failed to connect to web socket');
                        return [2 /*return*/];
                    }
                    stompClient.publish({
                        destination: '/app/connect',
                        body: JSON.stringify({ clientId: clientId, name: name })
                    });
                    return [3 /*break*/, 9];
                case 8:
                    e_2 = _a.sent();
                    addError('Failed to connect: ' + e_2.message);
                    return [3 /*break*/, 9];
                case 9:
                    setIsIrConnected(ir.isConnected());
                    return [2 /*return*/];
            }
        });
    }); }, [clientId, stompClient, name, addError, logMessage]);
    var disconnect = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!ir.isConnected()) {
                        setIsIrConnected(false);
                        addError('Not connected');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, ir.disconnect()];
                case 1:
                    _a.sent();
                    if (stompClient === undefined) {
                        setIsConnected(false);
                        addError('Failed to disconnect from server');
                        return [2 /*return*/];
                    }
                    stompClient.publish({
                        destination: '/app/disconnect',
                        body: JSON.stringify({ clientId: clientId })
                    });
                    setIsIrConnected(ir.isConnected());
                    return [2 /*return*/];
            }
        });
    }); }, [clientId, stompClient, addError]);
    var clearLog = useCallback(function () {
        setLog([]);
    }, []);
    var value = useMemo(function () { return ({
        available: hasIr,
        isIrConnected: isIrConnected,
        isConnected: isConnected,
        connect: connect,
        disconnect: disconnect,
        name: name,
        setName: setName,
        log: log,
        clearLog: clearLog,
        setConfig: setConfig,
        lastConfig: config,
        getPorts: getPorts
    }); }, [
        hasIr,
        isConnected,
        connect,
        disconnect,
        name,
        setName,
        log,
        clearLog,
        isIrConnected,
        setConfig,
        config,
        getPorts
    ]);
    return (_jsx(IRBasestationContext.Provider, { value: value, children: children }));
};
