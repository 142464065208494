var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import RadioIcon from '@mui/icons-material/Radio';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { useGroupConfigureIconMutation } from '../../../../api/labelApi';
import { ListItemInfo } from '../../../../components/atoms/ListItemInfo';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
export function GroupIcons(_a) {
    var _this = this;
    var label = _a.label, target = _a.target;
    var addApiError = useMessageHandler().addApiError;
    var add = usePendingContext().add;
    var configureIcon = useGroupConfigureIconMutation()[0];
    var onConfigureIcon = function (icon, enable) { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, configureIcon({
                            barcode: label.barcode,
                            icon: icon,
                            enable: enable,
                            target: target
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'SET-ICON', label.barcode);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to configure icon", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "icons-content", id: "icons-header", children: _jsx(Typography, { sx: { width: '33%', flexShrink: 0 }, children: "Icons" }) }), _jsxs(AccordionDetails, { children: [_jsx(ListItemInfo, { title: "User icon / radio", icon: _jsx(RadioIcon, {}), value: label.icons.user ? 'Enabled' : 'Disabled', onEdit: function () { return onConfigureIcon(9, false); }, editIcon: _jsx(NotificationsOffIcon, {}), onRefresh: function () { return onConfigureIcon(9, true); }, refreshIcon: _jsx(NotificationsIcon, {}) }), _jsx(ListItemInfo, { title: "Link icon", icon: _jsx(LinkIcon, {}), value: 'Enabled', onEdit: function () { return onConfigureIcon(10, false); }, editIcon: _jsx(NotificationsOffIcon, {}) }), _jsx(ListItemInfo, { title: "Temperature icon", icon: _jsx(ThermostatIcon, {}), value: "Enabled", onEdit: function () { return onConfigureIcon(11, false); }, editIcon: _jsx(NotificationsOffIcon, {}) }), _jsx(ListItemInfo, { title: "Battery icon", icon: _jsx(BatteryAlertIcon, {}), value: "Enabled", onEdit: function () { return onConfigureIcon(12, false); }, editIcon: _jsx(NotificationsOffIcon, {}) })] })] }));
}
