var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, Switch } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { fetchPreviewImage, useUploadImageMutation } from '../../../../api/labelApi';
import { useAuthentication } from '../../../../common/hooks/authentication';
import { FileUpload, readUploadFile } from '../../../../components/atoms/form/FileUpload';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
import { CompressionPicker } from '../atoms/CompressionPicker';
import { SizeInput } from '../atoms/SizeInput';
export function UploadImage(_a) {
    var _this = this;
    var _b, _c, _d, _e;
    var label = _a.label, target = _a.target, open = _a.open, onClose = _a.onClose, page = _a.page;
    var uploadImage = useUploadImageMutation()[0];
    var token = useAuthentication().token;
    var _f = React.useState(undefined), selectedFile = _f[0], setSelectedFile = _f[1];
    var _g = React.useState(false), resize = _g[0], setResize = _g[1];
    var _h = React.useState({ x: 0, y: 0 }), position = _h[0], setPosition = _h[1];
    var _j = React.useState({
        width: ((_b = label.display) === null || _b === void 0 ? void 0 : _b.width) || 0,
        height: ((_c = label.display) === null || _c === void 0 ? void 0 : _c.height) || 0
    }), size = _j[0], setSize = _j[1];
    var _k = React.useState(true), dither = _k[0], setDither = _k[1];
    var _l = React.useState('auto'), compression = _l[0], setCompression = _l[1];
    var _m = React.useState(''), previewUrl = _m[0], setPreviewUrl = _m[1];
    var _o = React.useState(true), overlayBarcode = _o[0], setOverlayBarcode = _o[1];
    var _p = React.useState(0), imageSize = _p[0], setImageSize = _p[1];
    var _q = React.useState(false), loadingPreview = _q[0], setLoadingPreview = _q[1];
    var _r = useMessageHandler(), addError = _r.addError, addApiError = _r.addApiError;
    var add = usePendingContext().add;
    var imageQuery = useMemo(function () {
        return ({
            barcode: label.barcode,
            page: page,
            resize: resize,
            width: resize ? size.width : 0,
            height: resize ? size.height : 0,
            x: resize ? position.x : 0,
            y: resize ? position.y : 0,
            dither: dither,
            compression: compression,
            image: '',
            overlayBarcode: overlayBarcode,
            target: target
        });
    }, [
        size,
        position,
        dither,
        compression,
        overlayBarcode,
        target,
        resize,
        page,
        label
    ]);
    var onSelectedFile = function (files) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (files.length === 0) {
                        setSelectedFile(undefined);
                        return [2 /*return*/, false];
                    }
                    setSelectedFile(files[0]);
                    return [4 /*yield*/, refreshPreview(files[0])];
                case 1:
                    _a.sent();
                    return [2 /*return*/, false];
            }
        });
    }); };
    var refreshPreview = useCallback(function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(_this, __spreadArray([], args_1, true), void 0, function (file) {
            var image, _a, size, preview;
            if (file === void 0) { file = selectedFile; }
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (file === undefined) {
                            setPreviewUrl('');
                            return [2 /*return*/];
                        }
                        setLoadingPreview(true);
                        return [4 /*yield*/, readUploadFile(file)];
                    case 1:
                        image = _b.sent();
                        return [4 /*yield*/, fetchPreviewImage(__assign(__assign({}, imageQuery), { image: image }), token || '')];
                    case 2:
                        _a = _b.sent(), size = _a.size, preview = _a.image;
                        setImageSize(+size);
                        setPreviewUrl(URL.createObjectURL(preview));
                        setLoadingPreview(false);
                        return [2 /*return*/];
                }
            });
        });
    }, [imageQuery, selectedFile, token]);
    useEffect(function () {
        refreshPreview().then(function () { });
    }, [imageQuery, refreshPreview]);
    var handleClose = function (changed) {
        setSelectedFile(undefined);
        setPreviewUrl('');
        onClose(changed);
    };
    var doUploadImage = function (image) { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, uploadImage(image).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'IMAGE', label.barcode);
                    return [2 /*return*/, true];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to update image", e_1);
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onResize = function (check) {
        if (check) {
            setOverlayBarcode(false);
        }
        setResize(check);
    };
    var upload = function () { return __awaiter(_this, void 0, void 0, function () {
        var image;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (selectedFile === undefined) {
                        addError('No file selected');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, readUploadFile(selectedFile)];
                case 1:
                    image = _a.sent();
                    return [4 /*yield*/, doUploadImage(__assign(__assign({}, imageQuery), { image: image }))];
                case 2:
                    if (_a.sent()) {
                        handleClose(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Dialog, { open: open, maxWidth: "lg", children: [_jsx(DialogTitle, { children: "How long do you want to show this page" }), _jsx(DialogContent, { children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, sm: 12, md: 6, children: _jsxs(Stack, { spacing: 3, sx: { minWidth: '350px' }, children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: resize, onChange: function (e, check) { return onResize(check); } }), label: " Partial update" }), resize && (_jsxs(_Fragment, { children: [_jsx(SizeInput, { sizeOrPos: true, value: { x: size.width, y: size.height }, onChange: function (width, height) { return setSize({ width: width, height: height }); } }), _jsx(SizeInput, { sizeOrPos: false, value: position, onChange: function (x, y) { return setPosition({ x: x, y: y }); } })] })), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: dither, onChange: function (e, check) { return setDither(check); } }), label: "Dither image" }), _jsx(CompressionPicker, { value: compression, onChange: function (value) { return setCompression(value); } }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: overlayBarcode, onChange: function (e, check) { return setOverlayBarcode(check); } }), label: " Overlay barcode" }), _jsx(FileUpload, { id: "labelImage", title: "Click to select image...", onUpload: onSelectedFile })] }) }), _jsx(Grid, { item: true, sm: 12, md: 6, children: _jsxs(Box, { sx: {
                                    width: (_d = label.display) === null || _d === void 0 ? void 0 : _d.width,
                                    height: (_e = label.display) === null || _e === void 0 ? void 0 : _e.height
                                }, children: [previewUrl.length > 0 && !loadingPreview && (_jsxs("div", { children: [_jsx("img", { src: previewUrl, alt: "Preview" }), _jsx("br", {}), "Estimated size ", imageSize, " bytes"] })), loadingPreview && _jsx(CircularProgress, { size: "2em" })] }) })] }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return handleClose(false); }, children: "Cancel" }), _jsx(PricerButton, { disabled: selectedFile === undefined, type: "ok", onClick: upload, children: "Upload" })] })] }));
}
