import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Auth0Provider } from '@auth0/auth0-react';
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Application from './Application';
import { getConfig } from './common/config';
import { AppEventsContextProvider } from './context/AppEvents';
import { MessageContextProvider } from './context/MessageContext';
import { store } from './store/store';
import { themeOptions } from './theme';
var AppRoot = function () {
    return (_jsxs(ThemeProvider, { theme: responsiveFontSizes(createTheme(themeOptions)), children: [_jsx(CssBaseline, {}), _jsx(Provider, { store: store, children: _jsx(Auth0Provider, { domain: getConfig().authentication.domain, clientId: getConfig().authentication.clientId, useRefreshTokensFallback: true, authorizationParams: {
                        audience: getConfig().authentication.audience,
                        redirect_uri: window.location.origin
                    }, children: _jsx(AppEventsContextProvider, { children: _jsx(MessageContextProvider, { children: _jsx(Router, { children: _jsx(Application, {}) }) }) }) }) })] }));
};
export default AppRoot;
