import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
var formatHex = function (val) { return val.toString(16).padStart(2, '0'); };
export function HexByte(_a) {
    var title = _a.title, inValue = _a.value, onChange = _a.onChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.max, max = _c === void 0 ? 255 : _c;
    var _d = React.useState(formatHex(inValue)), value = _d[0], setValue = _d[1];
    useEffect(function () {
        setValue(formatHex(inValue));
    }, [inValue]);
    var onBlur = function () {
        var val = parseInt(value, 16);
        var result = isNaN(val) || val < 0 || val > max ? 0 : val;
        onChange(result);
        setValue(formatHex(result));
    };
    return (_jsx(TextField, { required: true, disabled: disabled, label: title, value: value, onBlur: onBlur, onChange: function (e) { return setValue(e.target.value); } }));
}
