import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Chip, Typography } from '@mui/material';
import React from 'react';
import { relativeSeconds } from '../../../../common/helpers';
import { humanStatus } from '../../message';
export function MessageBody(_a) {
    var message = _a.message;
    var capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var duration = "".concat(relativeSeconds(message.received, message.sent), " seconds");
    var wasAcked = message.hasRequest && message.hasReply;
    var status = humanStatus(message.status);
    var statusColor = message.status === 'success'
        ? 'primary'
        : message.status === 'error'
            ? 'error'
            : 'warning';
    var direction = message.hasRequest ? 'sent' : 'received';
    return (_jsxs(Typography, { component: "div", variant: "body1", children: [capitalize(message.type), " message ", direction, status !== 'received' && (_jsxs(_Fragment, { children: ["\u00A0", _jsx(Chip, { label: status, size: "small", color: statusColor, variant: "outlined" })] })), wasAcked && (_jsxs(_Fragment, { children: [' ', "in", ' ', _jsx(Chip, { label: duration, size: "small", color: "primary", variant: "outlined" })] })), ".", message.error && (_jsxs(Alert, { sx: { marginTop: 2 }, severity: "error", children: [capitalize(message.type), " message ", direction, "\u00A0 Error:", ' ', message.error, "."] }))] }));
}
