var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { generate } from 'generate-password-browser';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getConfig } from '../../../../../common/config';
import { FormArea } from '../../../../../components/atoms/form/FormArea';
import { GeneratedPassword } from '../../../../../components/atoms/form/GeneratedPassword';
import { OkCancelArea } from '../../../../../components/atoms/form/OkCancelArea';
import { PricerButton } from '../../../../../components/atoms/PricerButton';
import { TopicPane } from './TopicPane';
export function UserEditor(_a) {
    var title = _a.title, buttonTitle = _a.buttonTitle, initialShowPassword = _a.showPassword, user = _a.user, onChange = _a.onChange, onSave = _a.onSave;
    var navigator = useNavigate();
    var _b = React.useState(initialShowPassword), showPassword = _b[0], setShowPassword = _b[1];
    var key = React.useState(generate({
        length: 30,
        numbers: true,
        symbols: true,
        uppercase: true,
        lowercase: true
    }))[0];
    useEffect(function () {
        if (!initialShowPassword) {
            setShowPassword(false);
        }
    }, [user, initialShowPassword, setShowPassword]);
    var doSave = function () {
        var password = showPassword ? key : undefined;
        onSave(__assign(__assign({}, user), { password: password }));
    };
    return (_jsxs(FormArea, { title: title, children: [_jsx(TextField, { label: "Username", variant: "outlined", value: user.id, onChange: function (e) { return onChange(__assign(__assign({}, user), { id: e.target.value })); }, autoFocus: true }), _jsx(TextField, { label: "Tenant", variant: "outlined", value: user.tenant, onChange: function (e) { return onChange(__assign(__assign({}, user), { tenant: e.target.value })); } }), _jsx(TextField, { label: "Address", variant: "outlined", value: getConfig().mqttAddress, disabled: true }), showPassword && (_jsx(GeneratedPassword, { id: "password", label: "Key", text: key })), !showPassword && (_jsx(PricerButton, { onClick: function () { return setShowPassword(true); }, children: "Change key" })), _jsx(TopicPane, { read: user.read, write: user.write, onChange: function (r, w) { return onChange(__assign(__assign({}, user), { read: r, write: w })); } }), _jsx(OkCancelArea, { onCancel: function () { return navigator('/tools/keys'); }, onOk: doSave, okTitle: buttonTitle })] }));
}
