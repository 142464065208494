import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
export function DeleteButton(_a) {
    var _b = _a.path, path = _b === void 0 ? '' : _b, onClick = _a.onClick;
    var navigator = useNavigate();
    var clickHandler = onClick ? onClick : function () { return navigator(path); };
    return (_jsx(_Fragment, { children: _jsx(IconButton, { onClick: clickHandler, children: _jsx(DeleteIcon, {}) }) }));
}
