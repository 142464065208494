var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Divider, Grid } from '@mui/material';
import React from 'react';
import { PricerPagination } from '../../../../components/atoms/PricerPagination';
import { PopupMenu } from '../../../../components/atoms/toolbar/PopupMenu';
import { PopupMenuItem } from '../../../../components/atoms/toolbar/PopupMenuItem';
import { LabelListItem } from '../atoms/LabelListItem';
import { BatchFlashDialog } from './BatchFlashDialog';
import { BatchLocateDialog } from './BatchLocateDialog';
import { BatchPatternDialog } from './BatchPatternDialog';
import { BatchPingDialog } from './BatchPingDialog';
import { BatchReadSensorDialog } from './BatchReadSensorDialog';
import { BatchSwitchPageDialog } from './BatchSwitchPageDialog';
import { BatchToggleIconDialog } from './BatchToggleIconDialog';
export function LabelList(_a) {
    var labels = _a.labels, onClick = _a.onClick, page = _a.page, setPage = _a.setPage;
    var _b = React.useState([]), selected = _b[0], setSelected = _b[1];
    var _c = React.useState(false), showSensor = _c[0], setShowSensor = _c[1];
    var _d = React.useState(false), showFlash = _d[0], setShowFlash = _d[1];
    var _e = React.useState(false), showLocate = _e[0], setShowLocate = _e[1];
    var _f = React.useState(false), showPing = _f[0], setShowPing = _f[1];
    var _g = React.useState(false), showPattern = _g[0], setShowPattern = _g[1];
    var _h = React.useState(false), showIcon = _h[0], setShowIcon = _h[1];
    var _j = React.useState(false), showPage = _j[0], setShowPage = _j[1];
    var selectedLabels = labels.content.filter(function (l) {
        return selected.includes(l.barcode);
    });
    var onSetPage = function (page) {
        setSelected([]);
        setPage(page);
    };
    var wrapOnClick = function (label) {
        if (selected.length > 0) {
            if (selected.includes(label.barcode)) {
                setSelected(function (s) { return s.filter(function (x) { return x !== label.barcode; }); });
            }
            else {
                setSelected(function (s) { return __spreadArray(__spreadArray([], s, true), [label.barcode], false); });
            }
        }
        else {
            onClick(label);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(BatchReadSensorDialog, { labels: selectedLabels, open: showSensor, onClose: function () { return setShowSensor(false); } }), _jsx(BatchFlashDialog, { labels: selectedLabels, open: showFlash, onClose: function () { return setShowFlash(false); } }), _jsx(BatchLocateDialog, { labels: selectedLabels, open: showLocate, onClose: function () { return setShowLocate(false); } }), _jsx(BatchPingDialog, { labels: selectedLabels, open: showPing, onClose: function () { return setShowPing(false); } }), _jsx(BatchPatternDialog, { labels: selectedLabels, open: showPattern, onClose: function () { return setShowPattern(false); } }), _jsx(BatchToggleIconDialog, { labels: selectedLabels, open: showIcon, onClose: function () { return setShowIcon(false); } }), _jsx(BatchSwitchPageDialog, { labels: selectedLabels, open: showPage, onClose: function () { return setShowPage(false); } }), _jsxs(Grid, { container: true, spacing: 2, children: [selected.length > 0 && (_jsx(Grid, { item: true, xs: 12, children: _jsxs(Alert, { severity: "success", action: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "text", onClick: function () {
                                            return setSelected(labels.content.map(function (b) { return b.barcode; }));
                                        }, children: "Select all" }), _jsx(Button, { variant: "text", onClick: function () { return setSelected([]); }, children: "Select none" }), _jsxs(PopupMenu, { children: [_jsx(PopupMenuItem, { onSelect: setShowPing, children: "Ping" }), _jsx(PopupMenuItem, { onSelect: setShowLocate, children: "Locate" }), _jsx(Divider, {}), _jsx(PopupMenuItem, { onSelect: setShowFlash, children: "Flash" }), _jsx(PopupMenuItem, { onSelect: setShowPattern, children: "Update pattern" }), _jsx(PopupMenuItem, { onSelect: setShowSensor, children: "Update sensor" }), _jsx(PopupMenuItem, { onSelect: setShowIcon, children: "Toggle icon" }), _jsx(PopupMenuItem, { onSelect: setShowPage, children: "Switch page" })] })] }), children: [selected.length, " item(s) selected."] }) })), labels.content.map(function (c) { return (_jsx(LabelListItem, { label: c, onClick: wrapOnClick, selected: selected.includes(c.barcode), onSelect: function (l) {
                            return setSelected(function (s) { return __spreadArray(__spreadArray([], s.filter(function (x) { return x !== l.barcode; }), true), [
                                l.barcode
                            ], false); });
                        }, onUnSelect: function (l) {
                            return setSelected(function (s) { return s.filter(function (x) { return x !== l.barcode; }); });
                        } }, c.barcode)); }), labels.content.length === 0 && _jsx(Grid, { item: true, children: "No labels found" })] }), _jsx(PricerPagination, { items: labels, page: page, setPage: onSetPage })] }));
}
