export var humanStatus = function (status) {
    if (status === 'success') {
        return 'successfully';
    }
    if (status === 'error') {
        return 'unsuccessfully';
    }
    if (status === 'received') {
        return 'received';
    }
    return 'but is pending';
};
