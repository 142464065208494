var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { transmissionApi, useAckMessageMutation, useGetMessageQuery } from '../../../../api/api';
import { buildPageFilterQuery } from '../../../../common/helpers';
import { useQuery } from '../../../../common/hooks/query';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
import { MessagePayload } from '../atoms/MessagePayload';
export function MessageView() {
    var _this = this;
    var dispatch = useAppDispatch();
    var query = useQuery();
    var id = useParams().id;
    var _a = useGetMessageQuery(id || ''), message = _a.data, isError = _a.isError, isFetching = _a.isFetching;
    var _b = useMessageHandler(), addError = _b.addError, addApiError = _b.addApiError;
    var ackMessage = useAckMessageMutation()[0];
    var page = parseInt(query.get('page') || '0');
    var filter = query.get('filter') || '';
    useEffect(function () {
        if (isError) {
            addError("Failed to fetch message ".concat(id));
        }
    }, [isError, addError, id]);
    var hasReply = (message === null || message === void 0 ? void 0 : message.reply) !== undefined && (message === null || message === void 0 ? void 0 : message.reply.length) > 0;
    var sendAck = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ackMessage((message === null || message === void 0 ? void 0 : message.id) || '').unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to ack ".concat(message === null || message === void 0 ? void 0 : message.id), e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var refreshApi = function () {
        dispatch(transmissionApi.util.invalidateTags(['Message']));
    };
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch message", toolbar: _jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/messages".concat(buildPageFilterQuery(page, filter)) }), _jsx(Spacing, {}), !hasReply && (_jsxs(Button, { variant: "text", onClick: sendAck, children: [' ', "Send ack", ' '] })), !hasReply && _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsx(RefreshButton, { onRefresh: refreshApi, isFetching: isFetching })] }), children: message && _jsx(MessagePayload, { isFetching: isFetching, message: message }) }));
}
