import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Pagination from '@mui/material/Pagination';
import React from 'react';
export function PricerPagination(_a) {
    var items = _a.items, page = _a.page, setPage = _a.setPage;
    if (items === undefined || items.pages === 0 || items.pages === 1) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(_Fragment, { children: _jsx(Pagination, { count: +items.pages, siblingCount: 3, page: page + 1, onChange: function (e, value) { return setPage(value - 1); } }) }));
}
