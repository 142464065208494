import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
export function ByteTable(_a) {
    var fields = _a.fields, _b = _a.values, values = _b === void 0 ? [] : _b;
    var getLen = function (field) { return field.length || 1; };
    var totalSections = fields.reduce(function (acc, field) { return acc + getLen(field); }, 0);
    var actualValues = values.slice(0, totalSections);
    if (values.length <= totalSections) {
        for (var i = values.length; i < totalSections; i++) {
            actualValues.push(0);
        }
    }
    var varargs = [];
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var field = fields_1[_i];
        for (var i = 0; i < getLen(field); i++) {
            varargs.push(field.vararg || false);
        }
    }
    var renderHex = function (value) { return value.toString(16).padStart(2, '0'); };
    return (_jsx(Table, { size: "small", sx: { border: 'solid black 1px', width: 'auto' }, children: _jsxs(TableBody, { children: [_jsx(TableRow, { children: fields.map(function (field, index) { return (_jsx(TableCell, { colSpan: field.length, sx: { border: 'solid black 1px' }, children: field.title }, "d-".concat(index))); }) }), _jsx(TableRow, { children: actualValues.map(function (value, index) { return (_jsxs(TableCell, { sx: { border: 'solid black 1px' }, children: [renderHex(value), varargs[index] && '..N'] }, "v-".concat(index))); }) })] }) }));
}
