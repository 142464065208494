var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import React from 'react';
import { useUploadTestImageMutation } from '../../../../api/labelApi';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
import { patterns } from '../../label';
import { CompressionPicker } from '../atoms/CompressionPicker';
export function UploadPattern(_a) {
    var _this = this;
    var label = _a.label, target = _a.target, open = _a.open, onClose = _a.onClose, page = _a.page;
    var uploadPattern = useUploadTestImageMutation()[0];
    var _b = React.useState('horizontal-lines'), pattern = _b[0], setPattern = _b[1];
    var _c = React.useState('auto'), compression = _c[0], setCompression = _c[1];
    var _d = React.useState(true), overlayBarcode = _d[0], setOverlayBarcode = _d[1];
    var addApiError = useMessageHandler().addApiError;
    var add = usePendingContext().add;
    var handleClose = function (changed) {
        onClose(changed);
    };
    var onUploadImage = function () { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, uploadPattern({
                            barcode: label.barcode,
                            target: target,
                            pattern: pattern,
                            page: page,
                            overlayBarcode: overlayBarcode,
                            compression: compression
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'TEST_PATTERN', label.barcode);
                    onClose(true);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to update image", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Dialog, { open: open, maxWidth: "lg", children: [_jsx(DialogTitle, { children: "Upload test image" }), _jsx(DialogContent, { children: _jsxs(Stack, { spacing: 3, sx: { minWidth: '350px' }, children: [_jsxs(FormControl, { children: [_jsx(InputLabel, { id: "page-label", children: "Test pattern" }), _jsx(Select, { sx: { maxWidth: 400 }, labelId: "page-label", size: "small", value: pattern, label: "Test pattern", onChange: function (e) { return setPattern(e.target.value); }, children: Object.entries(patterns).map(function (_a) {
                                        var k = _a[0], v = _a[1];
                                        return (_jsx(MenuItem, { value: k, children: v }, k));
                                    }) })] }), _jsx(FormControlLabel, { control: _jsx(Switch, { checked: overlayBarcode, onChange: function (e, check) { return setOverlayBarcode(check); } }), label: " Overlay barcode" }), _jsx(CompressionPicker, { value: compression, onChange: function (value) { return setCompression(value); } })] }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return handleClose(false); }, children: "Cancel" }), _jsx(PricerButton, { disabled: pattern === '', type: "ok", onClick: onUploadImage, children: "Upload" })] })] }));
}
