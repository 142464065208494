var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import TitleIcon from '@mui/icons-material/Title';
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, TextField, Typography } from '@mui/material';
import React from 'react';
import { useUpdateLabelMutation } from '../../../../api/labelApi';
import { relativeTime } from '../../../../common/helpers';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { TagEditor } from '../../../../components/atoms/form/TagEditor';
import { ListItemInfo } from '../../../../components/atoms/ListItemInfo';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
export function GroupDetails(_a) {
    var _this = this;
    var _b, _c;
    var label = _a.label;
    var addApiError = useMessageHandler().addApiError;
    var updateLabel = useUpdateLabelMutation()[0];
    var _d = React.useState(false), openEdit = _d[0], setOpenEdit = _d[1];
    var _e = React.useState(label.title || ''), newTitle = _e[0], setNewTitle = _e[1];
    var _f = React.useState(((_b = label.ir) === null || _b === void 0 ? void 0 : _b.keycode) || 0), newKeyCode = _f[0], setNewKeyCode = _f[1];
    var _g = React.useState(label.tags || []), newTags = _g[0], setNewTags = _g[1];
    var saveLabel = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateLabel({
                            barcode: label.barcode,
                            title: newTitle,
                            keycode: newKeyCode,
                            tags: newTags
                        }).unwrap()];
                case 1:
                    _a.sent();
                    setOpenEdit(false);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to save label", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsxs(Accordion, { children: [_jsxs(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "details-content", id: "details-header", children: [_jsx(Typography, { sx: { width: '33%', flexShrink: 0 }, children: "Details" }), _jsxs(Typography, { sx: {
                                display: { xs: 'none', sm: 'block' },
                                color: 'text.secondary'
                            }, children: ["Last used: ", relativeTime(label === null || label === void 0 ? void 0 : label.lastSeen)] })] }), _jsxs(AccordionDetails, { children: [_jsxs(Dialog, { open: openEdit, children: [_jsx(DialogTitle, { children: "Change the label title" }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: _jsxs(FormArea, { children: [_jsx(TextField, { label: "Title", value: newTitle, onChange: function (e) { return setNewTitle(e.target.value); } }), _jsx(TagEditor, { title: "Tags", tags: newTags, setTags: setNewTags }), _jsx(TextField, { label: "Keycode", value: '' + newKeyCode, onChange: function (e) { return setNewKeyCode(+e.target.value); } })] }) }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return setOpenEdit(false); }, children: "Cancel" }), _jsx(PricerButton, { type: "ok", onClick: saveLabel, children: "Save" })] })] }), _jsxs(List, { children: [_jsx(ListItemInfo, { title: "Title", icon: _jsx(TitleIcon, {}), value: label === null || label === void 0 ? void 0 : label.title, onEdit: function () { return setOpenEdit(true); } }), _jsx(ListItemInfo, { title: "Keycode", icon: _jsx(SignalCellular1BarIcon, {}), value: '' + (((_c = label.ir) === null || _c === void 0 ? void 0 : _c.keycode) || 0), onEdit: function () { return setOpenEdit(true); } }), _jsx(ListItemInfo, { title: "Last used", icon: _jsx(QueryBuilderIcon, {}), value: relativeTime(label === null || label === void 0 ? void 0 : label.lastSeen) })] })] })] }) }));
}
