var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import React from 'react';
import JSONPretty from 'react-json-pretty';
import { useNavigate } from 'react-router';
import { RelativeDateChip } from '../../../../components/atoms/RelativeDateChip';
import { MessageBody } from './MessageBody';
import { MessageIcon } from './MessageIcon';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
export function MessagePayload(_a) {
    var message = _a.message;
    var navigator = useNavigate();
    var hasRequest = message.request !== undefined && message.request.length > 0;
    var hasReply = message.reply !== undefined && message.reply.length > 0;
    var liteMessage = __assign(__assign({}, message), { hasRequest: hasRequest, hasReply: hasReply });
    return (_jsxs(_Fragment, { children: [_jsxs(Typography, { paragraph: true, variant: "h4", children: [_jsx(MessageIcon, { type: message.type, status: message.status }), ' ', message.type, " -\u00A0", _jsx(Link, { component: "button", onClick: function () { return navigator("/labels/".concat(message.barcode)); }, children: message.barcode })] }), _jsxs(Typography, { variant: "subtitle1", children: ["Message processed", ' ', _jsx(RelativeDateChip, { date: message.sent, size: "small", color: "primary" })] }), _jsx(MessageBody, { message: liteMessage }), hasRequest && (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", children: "Request:" }), _jsx(JSONPretty, { id: "json-pretty", data: message.request, theme: JSONPrettyMon, style: { marginTop: '0px' } })] })), hasReply && (_jsxs(_Fragment, { children: [hasRequest && _jsx(Typography, { variant: "subtitle2", children: "Reply:" }), _jsx(JSONPretty, { id: "json-pretty", data: message === null || message === void 0 ? void 0 : message.reply, theme: JSONPrettyMon, style: { marginTop: '0px' } })] }))] }));
}
