import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
export function ListItemInfo(_a) {
    var title = _a.title, value = _a.value, icon = _a.icon, link = _a.link, onRefresh = _a.onRefresh, onEdit = _a.onEdit, editTitle = _a.editTitle, refreshTitle = _a.refreshTitle, _b = _a.editIcon, editIcon = _b === void 0 ? _jsx(DriveFileRenameOutlineIcon, {}) : _b, _c = _a.refreshIcon, refreshIcon = _c === void 0 ? _jsx(RefreshIcon, {}) : _c;
    var navigator = useNavigate();
    var refreshTitleElement = refreshTitle ? (_jsxs(Typography, { component: "span", children: [refreshTitle, " "] })) : undefined;
    var refreshAction = onRefresh ? (_jsxs(_Fragment, { children: [refreshTitleElement, _jsx(IconButton, { edge: "end", "aria-label": "refresh", onClick: onRefresh, children: refreshIcon })] })) : undefined;
    var editTitleElement = editTitle ? (_jsxs(Typography, { component: "span", children: [editTitle, " "] })) : undefined;
    var editAction = onEdit ? (_jsxs(_Fragment, { children: [editTitleElement, _jsx(IconButton, { edge: "end", "aria-label": "refresh", onClick: onEdit, children: editIcon })] })) : undefined;
    var action = refreshAction && editAction ? (_jsxs(_Fragment, { children: [refreshAction, " ", editAction] })) : (refreshAction || editAction);
    return (_jsxs(ListItem, { secondaryAction: action, children: [link && (_jsxs(ListItemButton, { onClick: function () { return navigator(link); }, disableGutters: true, children: [icon && _jsx(ListItemIcon, { children: icon }), _jsx(ListItemText, { primary: title, secondary: value })] })), !link && (_jsxs(_Fragment, { children: [icon && _jsx(ListItemIcon, { children: icon }), _jsx(ListItemText, { primary: title, secondary: value })] }))] }));
}
