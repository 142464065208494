var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TextureIcon from '@mui/icons-material/Texture';
import TimerIcon from '@mui/icons-material/Timer';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useFlashLabelMutation } from '../../../../api/labelApi';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
import { greenColorList, rgbColorList } from '../../label';
import { FlashFormInput } from '../atoms/FlashFormInput';
import { FlashSlider } from '../atoms/FlashSlider';
export function Flash(_a) {
    var _this = this;
    var label = _a.label, target = _a.target;
    var flashLabel = useFlashLabelMutation()[0];
    var add = usePendingContext().add;
    var addApiError = useMessageHandler().addApiError;
    var _b = useLocalStorage('label.show.flash', false), expanded = _b[0], setExpanded = _b[1];
    var _c = React.useState('AAAAAAAAAAFAFA'), pattern = _c[0], setPattern = _c[1];
    var _d = React.useState(1), repeatType = _d[0], setRepeatType = _d[1];
    var _e = React.useState(0), repeatDuration = _e[0], setRepeatDuration = _e[1];
    var _f = React.useState(1), brightness = _f[0], setBrightness = _f[1];
    var _g = React.useState(1), red = _g[0], setRed = _g[1];
    var _h = React.useState(1), green = _h[0], setGreen = _h[1];
    var _j = React.useState(1), blue = _j[0], setBlue = _j[1];
    var _k = React.useState(false), showCustom = _k[0], setShowCustom = _k[1];
    var flash = function (red, green, blue) { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, flashLabel({
                            barcode: label.barcode,
                            ledIndex: 0,
                            red: red,
                            green: green,
                            blue: blue,
                            brightness: 3,
                            pattern: 'AAAAAAAAAAFAFA',
                            repeatType: 1,
                            repeatDuration: 0,
                            target: target
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'FLASH', label.barcode);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addApiError("Failed to flash label", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var customFlash = function () { return __awaiter(_this, void 0, void 0, function () {
        var requestId, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, flashLabel({
                            barcode: label.barcode,
                            ledIndex: 0,
                            red: red,
                            green: green,
                            blue: blue,
                            brightness: brightness,
                            pattern: pattern,
                            repeatType: repeatType,
                            repeatDuration: repeatDuration,
                            target: target
                        }).unwrap()];
                case 1:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'FLASH', label.barcode);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    addApiError('Failed to flash label', e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var hasRgb = label.ledType === 'RGB';
    var colors = hasRgb ? rgbColorList : greenColorList;
    var theme = useTheme();
    var matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    var dir = matchDownMd ? 'column' : 'row';
    var spacing = matchDownMd ? 1 : 3;
    return (_jsxs(Accordion, { expanded: expanded, onChange: function (e, isExpanded) { return setExpanded(isExpanded); }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "icons-content", id: "icons-header", children: _jsx(Typography, { sx: { width: '33%', flexShrink: 0 }, children: "Flash" }) }), _jsxs(AccordionDetails, { children: [_jsxs(Stack, { direction: dir, spacing: spacing, children: [Object.entries(colors).map(function (_a) {
                                var color = _a[0], code = _a[1];
                                return (_jsx(Button, { onClick: function () { return flash(code[0], code[1], code[2]); }, sx: { color: color }, variant: color === 'White' || color === 'Yellow' ? 'contained' : 'text', children: color }, color));
                            }), _jsx(PricerButton, { onClick: function () { return setShowCustom(true); }, children: "Custom" })] }), _jsxs(Dialog, { open: showCustom, children: [_jsx(DialogTitle, { children: "Custom flash" }), _jsx(DialogContent, { children: _jsxs(Stack, { direction: "column", spacing: 2, sx: { maxWidth: '350px' }, children: [_jsx(FlashSlider, { color: "red", value: red, onChange: function (v) { return setRed(v); } }), _jsx(FlashSlider, { color: "green", value: green, onChange: function (v) { return setGreen(v); } }), _jsx(FlashSlider, { color: "blue", value: blue, onChange: function (v) { return setBlue(v); } }), _jsx(FlashSlider, { color: "brightness", value: brightness, onChange: function (v) { return setBrightness(v); } }), _jsx(FlashFormInput, { title: "Pattern", value: pattern, onChange: function (v) { return setPattern(v); }, icon: _jsx(TextureIcon, {}) }), _jsx(FlashFormInput, { title: "Repeate Type", value: '' + repeatType, onChange: function (v) { return setRepeatType(+v); }, icon: _jsx(QuestionMarkIcon, {}) }), _jsx(FlashFormInput, { title: "Repeate Duration", value: '' + repeatDuration, onChange: function (v) { return setRepeatDuration(+v); }, icon: _jsx(TimerIcon, {}) })] }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: function () { return setShowCustom(false); }, children: "Cancel" }), _jsx(PricerButton, { onClick: customFlash, children: "Flash" })] })] })] })] }));
}
