import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { usePendingContext } from '../../../../context/PendingContext';
export function PendingMessages() {
    var _a = usePendingContext(), get = _a.get, has = _a.has;
    var messages = get();
    var count = messages.length;
    var plural = count > 1 ? 's' : '';
    var tooltip = messages.map(function (m) { return "".concat(m.type, " ").concat(m.barcode); }).join(', ');
    return (_jsx(_Fragment, { children: has() && (_jsxs(Stack, { direction: "row", spacing: 2, children: [_jsx(CircularProgress, { size: 30 }), _jsx(Tooltip, { sx: { display: { xs: 'none', sm: 'block' } }, title: tooltip, children: _jsxs(Typography, { variant: "body1", children: [' ', count, " pending message", plural] }) })] })) }));
}
