import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ZoneListItem } from '../atoms/ZoneListItem';
export function ZoneList(_a) {
    var zones = _a.zones, goto = _a.goto;
    var onClick = function (zone) {
        goto(zone.id);
    };
    return (_jsx(_Fragment, { children: zones.map(function (m) { return (_jsx(ZoneListItem, { zone: m, onClick: onClick }, m.id)); }) }));
}
