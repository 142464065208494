import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Card, CardActionArea, CardHeader, CardMedia, Grid, IconButton } from '@mui/material';
import React from 'react';
import { getConfig } from '../../../../common/config';
import { relativeTime } from '../../../../common/helpers';
import { useAuthentication } from '../../../../common/hooks/authentication';
export function LabelListItem(_a) {
    var label = _a.label, onClick = _a.onClick, selected = _a.selected, onSelect = _a.onSelect, onUnSelect = _a.onUnSelect;
    var token = useAuthentication().token;
    var url = "".concat(getConfig().apiBase, "/transmission/api/v1/labels/").concat(label.barcode, "/image/").concat(0, "?access_token=").concat(token);
    var date = function (date) {
        return date === undefined ? 'Never' : relativeTime(date);
    };
    var title = label.title && label.title.length > 0 ? label.title : label.barcode;
    var subheader = date(label === null || label === void 0 ? void 0 : label.lastSeen);
    var handleClick = function (event) {
        if (selected) {
            onUnSelect(label);
        }
        else {
            onSelect(label);
        }
        event.stopPropagation();
    };
    return (_jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(Card, { children: _jsxs(CardActionArea, { component: "div", onClick: function () { return onClick(label); }, children: [_jsx(CardHeader, { title: title, subheader: subheader, action: _jsxs(IconButton, { "aria-label": "select", onClick: handleClick, children: [!selected && _jsx(CheckBoxOutlineBlankIcon, {}), selected && _jsx(CheckBoxIcon, {})] }) }), _jsx(CardMedia, { component: "img", sx: { objectFit: 'scale-down', width: 1, height: '100px' }, image: url })] }) }) }, label.barcode));
}
