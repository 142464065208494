import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import React from 'react';
var toTextString = function (arr) {
    var textString = '';
    var i = 0;
    for (var _i = 0, arr_1 = arr; _i < arr_1.length; _i++) {
        var val = arr_1[_i];
        textString += val > 31 && val < 127 ? String.fromCharCode(val) : '.';
        if (i++ % 16 === 15) {
            textString += '\n';
        }
    }
    return textString;
};
export function TextView(_a) {
    var value = _a.value;
    return (_jsx(TextField, { inputProps: { style: { fontFamily: 'monospace' } }, fullWidth: true, disabled: true, label: "Text", value: toTextString(value), multiline: true, sx: { fontFamily: 'monospace' }, rows: 4 }));
}
