var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSaveUntrustedMutation } from '../../../../api/api';
import { useAuthentication } from '../../../../common/hooks/authentication';
import { FormArea } from '../../../../components/atoms/form/FormArea';
import { OkCancelArea } from '../../../../components/atoms/form/OkCancelArea';
import { PricerSwitch } from '../../../../components/atoms/form/PricerSwitch';
import { useMessageHandler } from '../../../../context/MessageContext';
export function UntrustedAccessPointView(_a) {
    var _this = this;
    var accessPoint = _a.accessPoint;
    var addApiError = useMessageHandler().addApiError;
    var navigator = useNavigate();
    var tenant = useAuthentication().tenant;
    var hasSecret = (accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.hasSecret) || false;
    var _b = React.useState((accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.hardwareId) || ''), hardwareId = _b[0], setHardwareId = _b[1];
    var _c = React.useState((accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.name) || ''), newName = _c[0], setNewName = _c[1];
    var _d = React.useState(tenant || ''), newTenant = _d[0], setNewTenant = _d[1];
    var _e = React.useState((accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.trusted) || false), newTrusted = _e[0], setNewTrusted = _e[1];
    var _f = React.useState(''), newSecret = _f[0], setNewSecret = _f[1];
    var _g = React.useState(!hasSecret), secretEnabled = _g[0], setSecretEnabled = _g[1];
    var _h = React.useState(false), customUrl = _h[0], setCustomUrl = _h[1];
    var _j = React.useState((accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.url) || ''), url = _j[0], setUrl = _j[1];
    var _k = React.useState(false), busy = _k[0], setBusy = _k[1];
    var saveAccessPoint = useSaveUntrustedMutation()[0];
    var isCalisto = (accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.hardwareId) !== undefined &&
        (accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.hardwareId.length) > 16 &&
        (accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.hardwareId.substring(12, 16)) === '5086';
    var onSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setBusy(true);
                    return [4 /*yield*/, saveAccessPoint({
                            hardwareId: hardwareId,
                            name: newName,
                            tenant: newTenant,
                            trusted: newTrusted,
                            secret: secretEnabled ? newSecret : undefined,
                            url: url
                        }).unwrap()];
                case 1:
                    _a.sent();
                    setBusy(false);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    setBusy(false);
                    addApiError("Failed to save access-point", e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var editTitle = (accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.name)
        ? "".concat(accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.name, " (").concat(accessPoint === null || accessPoint === void 0 ? void 0 : accessPoint.hardwareId, ")")
        : hardwareId;
    var title = accessPoint ? editTitle : 'Add new access-point';
    var secretTitle = hasSecret ? 'Replace device secret' : 'Set device secret';
    return (_jsx(Paper, { sx: { p: 3 }, children: _jsxs(FormArea, { title: title, children: [accessPoint === undefined && (_jsx(TextField, { label: "Hardware id", value: hardwareId, onChange: function (e) { return setHardwareId(e.target.value); } })), _jsx(TextField, { label: "Name", value: newName, onChange: function (e) { return setNewName(e.target.value); } }), _jsx(TextField, { label: "Tenant", value: newTenant, onChange: function (e) { return setNewTenant(e.target.value); } }), isCalisto && hasSecret && (_jsx(PricerSwitch, { checked: secretEnabled, onChange: setSecretEnabled, title: "There is already a secret configure do you want to replace it?" })), isCalisto && secretEnabled && (_jsx(TextField, { label: secretTitle, value: newSecret, onChange: function (e) { return setNewSecret(e.target.value); } })), _jsx(PricerSwitch, { checked: newTrusted, onChange: setNewTrusted, title: "Trusted" }), _jsx(PricerSwitch, { checked: customUrl, onChange: setCustomUrl, title: "Set custom URL" }), customUrl && (_jsx(TextField, { label: "Custom url", value: url, onChange: function (e) { return setUrl(e.target.value); } })), _jsx(OkCancelArea, { onCancel: function () { return navigator('/access-points/untrusted'); }, onOk: onSave, busy: busy, okTitle: "Save" })] }) }));
}
