import { jsx as _jsx } from "react/jsx-runtime";
import { Card, CardActionArea, CardHeader, Grid } from '@mui/material';
import React from 'react';
export function ItemListItem(_a) {
    var _b, _c;
    var item = _a.item, onClick = _a.onClick;
    var title = ((_b = item.itemData) === null || _b === void 0 ? void 0 : _b.title) || item.itemId;
    var price = ((_c = item.itemData) === null || _c === void 0 ? void 0 : _c.price) || '';
    return (_jsx(Grid, { item: true, xs: 12, md: 4, children: _jsx(Card, { children: _jsx(CardActionArea, { onClick: function () { return onClick(item); }, children: _jsx(CardHeader, { title: title, subheader: price }) }) }) }));
}
