import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { transmissionApi, useListAccessPointsQuery } from '../../../../api/api';
import { PricerPagination } from '../../../../components/atoms/PricerPagination';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
import { AccessPointListItem } from '../atoms/AccessPointListItem';
export function AccessPointListView() {
    var navigator = useNavigate();
    var addError = useMessageHandler().addError;
    var dispatch = useAppDispatch();
    var _a = React.useState(0), page = _a[0], setPage = _a[1];
    var _b = useListAccessPointsQuery({ page: page }), accessPoints = _b.data, isFetching = _b.isFetching, isError = _b.isError, error = _b.error;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch access-points');
        }
    }, [isError, addError, error]);
    var onRefresh = function () {
        dispatch(transmissionApi.util.invalidateTags(['AccessPoints', 'AccessPoint']));
    };
    return (_jsxs(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch access-points", hasPaper: false, toolbar: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "outlined", color: "secondary", onClick: function () { return navigator('/access-points/untrusted'); }, children: "Untrusted" }), _jsx(Spacing, {}), _jsx(RefreshButton, { isFetching: isFetching, onRefresh: onRefresh })] }), children: [accessPoints &&
                accessPoints.content &&
                accessPoints.content.map(function (ap) { return (_jsx(AccessPointListItem, { accessPoint: ap, onClick: function (ap) { return navigator('/access-points/' + ap.id); } }, ap.id)); }), accessPoints && accessPoints.content && (_jsx(PricerPagination, { items: accessPoints, page: page, setPage: setPage }))] }));
}
