var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useUploadFirmwareMutation } from '../../../../api/labelApi';
import { FileUpload, readUploadFile } from '../../../../components/atoms/form/FileUpload';
import { PricerButton } from '../../../../components/atoms/PricerButton';
import { useMessageHandler } from '../../../../context/MessageContext';
import { usePendingContext } from '../../../../context/PendingContext';
export function FirmwareUpgradeDialog(_a) {
    var _this = this;
    var label = _a.label, open = _a.open, onClose = _a.onClose;
    var _b = useMessageHandler(), addError = _b.addError, addApiError = _b.addApiError;
    var add = usePendingContext().add;
    var uploadFirmware = useUploadFirmwareMutation()[0];
    var _c = React.useState(undefined), selectedFile = _c[0], setSelectedFile = _c[1];
    var doUpload = function () { return __awaiter(_this, void 0, void 0, function () {
        var firmware, requestId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedFile) {
                        addError('No file selected');
                        return [2 /*return*/, false];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, readUploadFile(selectedFile)];
                case 2:
                    firmware = _a.sent();
                    return [4 /*yield*/, uploadFirmware({
                            barcode: label.barcode,
                            firmware: firmware
                        }).unwrap()];
                case 3:
                    requestId = (_a.sent()).requestId;
                    add(requestId, 'FIRMWARE', label.barcode);
                    onClose();
                    return [2 /*return*/, true];
                case 4:
                    e_1 = _a.sent();
                    addApiError('Failed to upload firmware', e_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/, false];
            }
        });
    }); };
    var onUpload = function (files) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedFile(files[0]);
            return [2 /*return*/, false];
        });
    }); };
    return (_jsxs(Dialog, { open: open, children: [_jsxs(DialogTitle, { children: ["Firmware upgrade ", label.barcode, "."] }), _jsx(DialogContent, { children: _jsx(FileUpload, { id: "firmwareImage", title: "Select Firmware", onUpload: onUpload }) }), _jsxs(DialogActions, { children: [_jsx(PricerButton, { onClick: onClose, children: "Cancel" }), _jsx(PricerButton, { type: "dangerous", disabled: !selectedFile, onClick: doUpload, children: "Upload" })] })] }));
}
