import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
export var ModuleSelector = styled(Tabs)({
    color: 'black',
    paddingLeft: '10px',
    '& .MuiButtonBase-root': {
        textTransform: 'none',
        color: 'black'
    },
    '.Mui-selected': {
        color: 'black'
    },
    '& .Mui-selected': {
        minHeight: '28px',
        color: 'black',
        backgroundColor: '#f9fcfc',
        borderRadius: '28px',
        marginTop: '18px',
        marginBottom: '18px',
        paddingTop: '3px',
        paddingBottom: '3px',
        fontWeight: 'bold'
    },
    '& .MuiTabs-indicator': {
        display: 'none'
    }
});
