import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { getConfig } from '../../../../common/config';
import { useAuthentication } from '../../../../common/hooks/authentication';
export function CurlBox(_a) {
    var path = _a.path, _b = _a.payload, payload = _b === void 0 ? undefined : _b, _c = _a.mimeType, mimeType = _c === void 0 ? 'application/json' : _c, _d = _a.acceptType, acceptType = _d === void 0 ? 'application/json' : _d, _e = _a.fileName, fileName = _e === void 0 ? 'IMAGE.PNG' : _e, _f = _a.title, title = _f === void 0 ? '' : _f;
    var token = useAuthentication().token;
    var server = getConfig().apiBase;
    var data = payload
        ? "-d '".concat(JSON.stringify(payload), "'")
        : "--data-binary @".concat(fileName);
    var code = "curl \\\n  -H \"Accept: ".concat(acceptType, "\" \\\n  -H \"Content-Type: ").concat(mimeType, "\" \\\n  -H \"Authorization: Bearer ").concat(token, "\" \\\n  '").concat(server).concat(path, "' \\\n  ").concat(data);
    return (_jsxs(Accordion, { sx: {
            maxWidth: { sm: 400, md: 800 },
            display: { xs: 'none', sm: 'block' }
        }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), children: _jsxs(Typography, { children: ["Show cUrl call ", title] }) }), _jsx(AccordionDetails, { children: _jsx(CopyBlock, { text: code, language: "bash", showLineNumbers: false, wrapLongLines: false, customStyle: { overflowX: 'scroll', borderRadius: '5px' }, theme: dracula }) })] }));
}
