import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { transmissionApi, useListUntrustedQuery } from '../../../../api/api';
import { AddButton } from '../../../../components/atoms/toolbar/AddButton';
import { BackButton } from '../../../../components/atoms/toolbar/BackButton';
import { RefreshButton } from '../../../../components/atoms/toolbar/RefreshButton';
import { Spacing } from '../../../../components/atoms/toolbar/Spacing';
import { SimplePageContent } from '../../../../components/templates/SimplePageContent';
import { useMessageHandler } from '../../../../context/MessageContext';
import { useAppDispatch } from '../../../../store/store';
import { UntrustedAccessPointListItem } from '../atoms/UntrustedAccessPointListItem';
export function UntrustedAccessPointListView() {
    var navigator = useNavigate();
    var addError = useMessageHandler().addError;
    var dispatch = useAppDispatch();
    var _a = useListUntrustedQuery(), accessPoints = _a.data, isFetching = _a.isFetching, isError = _a.isError, error = _a.error;
    useEffect(function () {
        if (isError) {
            addError('Failed to fetch access-points');
        }
    }, [isError, addError, error]);
    var onRefresh = function () {
        dispatch(transmissionApi.util.invalidateTags(['AccessPoints', 'AccessPoint']));
    };
    return (_jsx(SimplePageContent, { isError: isError, errorMessage: "Failed to fetch access-points", hasPaper: false, toolbar: _jsxs(_Fragment, { children: [_jsx(BackButton, { path: "/access-points" }), _jsx(Spacing, {}), _jsx(RefreshButton, { onRefresh: onRefresh, isFetching: isFetching }), _jsx(AddButton, { path: "/access-points/untrusted/new" })] }), children: accessPoints &&
            accessPoints.map(function (ap) { return (_jsx(UntrustedAccessPointListItem, { accessPoint: ap, onClick: function (ap) {
                    return navigator('/access-points/untrusted/' + ap.hardwareId);
                } }, ap.hardwareId)); }) }));
}
