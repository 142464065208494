var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import { Chip, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Stack } from '@mui/material';
import React from 'react';
export function TagEditor(_a) {
    var title = _a.title, tags = _a.tags, setTags = _a.setTags;
    var _b = React.useState(''), newTag = _b[0], setTag = _b[1];
    var addTag = function () {
        if (newTag !== '') {
            setTags(__spreadArray(__spreadArray([], tags, true), [newTag], false));
            setTag('');
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Stack, { direction: "row", spacing: 1, children: tags.map(function (t) { return (_jsx(Chip, { label: t, onDelete: function () { return setTags(tags.filter(function (tag) { return tag !== t; })); } }, t)); }) }), _jsxs(FormControl, { children: [_jsx(InputLabel, { htmlFor: "add-tag", children: title }), _jsx(FilledInput, { id: "add-tag", value: newTag, onBlur: addTag, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: addTag, edge: "end", children: _jsx(AddIcon, {}) }) }), onChange: function (e) { return setTag(e.target.value); } })] })] }));
}
