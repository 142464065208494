import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CategoryIcon from '@mui/icons-material/Category';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@mui/material';
import React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { getConfig } from '../../../../common/config';
import { useAuthentication } from '../../../../common/hooks/authentication';
import { ListItemInfo } from '../../../../components/atoms/ListItemInfo';
import { getPricerLabelType } from '../../label';
export function PhysicalCharacteristics(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var label = _a.label, plType = _a.plType;
    var token = useAuthentication().token;
    var _k = useLocalStorage('label.show.physical', true), expanded = _k[0], setExpanded = _k[1];
    var displaySize = "".concat((_b = label === null || label === void 0 ? void 0 : label.display) === null || _b === void 0 ? void 0 : _b.width, " x ").concat((_c = label === null || label === void 0 ? void 0 : label.display) === null || _c === void 0 ? void 0 : _c.height, " (").concat(((_d = label === null || label === void 0 ? void 0 : label.display) === null || _d === void 0 ? void 0 : _d.type) || '?', ")");
    var previewUrl = "".concat(getConfig().apiBase, "/transmission/api/v1/labels/").concat(label.barcode, "/image/").concat(label.currentPage, "?access_token=").concat(token);
    var type = plType
        ? "".concat(plType === null || plType === void 0 ? void 0 : plType.plType, ": ").concat(plType === null || plType === void 0 ? void 0 : plType.name)
        : '' + getPricerLabelType(label.barcode);
    var led = label.ledType === 'RGB' ? 'Rgb' : 'Green';
    var hasVersions = ((_e = label === null || label === void 0 ? void 0 : label.hardwareRevision) === null || _e === void 0 ? void 0 : _e.value) !== undefined &&
        ((_f = label === null || label === void 0 ? void 0 : label.firmware) === null || _f === void 0 ? void 0 : _f.value) !== undefined;
    var typeLink = hasVersions
        ? "/label-types/".concat(plType === null || plType === void 0 ? void 0 : plType.plType, "/").concat((_g = label === null || label === void 0 ? void 0 : label.hardwareRevision) === null || _g === void 0 ? void 0 : _g.value, "/").concat((_h = label === null || label === void 0 ? void 0 : label.firmware) === null || _h === void 0 ? void 0 : _h.value)
        : "/label-types/".concat(plType === null || plType === void 0 ? void 0 : plType.plType);
    return (_jsx(_Fragment, { children: _jsxs(Accordion, { expanded: expanded, onChange: function (e, isExpanded) { return setExpanded(isExpanded); }, children: [_jsxs(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "physical-content", id: "physical-header", children: [_jsx(Typography, { sx: { width: { xs: 1, sm: '33%' }, flexShrink: 0 }, children: "Characteristics" }), _jsx(Typography, { sx: {
                                display: { xs: 'none', sm: 'block' },
                                color: 'text.secondary'
                            }, children: displaySize })] }), _jsxs(AccordionDetails, { children: [previewUrl && (_jsx("div", { children: _jsx("img", { style: {
                                    maxWidth: '100%',
                                    objectFit: 'scale-down',
                                    height: '200px'
                                }, src: previewUrl, alt: "Preview" }) })), _jsxs(List, { children: [_jsx(ListItemInfo, { title: "Display", icon: _jsx(AspectRatioIcon, {}), value: displaySize }), _jsx(ListItemInfo, { title: "Pages", icon: _jsx(AutoStoriesIcon, {}), value: '' + ((_j = label.display) === null || _j === void 0 ? void 0 : _j.pages) || '?' }), _jsx(ListItemInfo, { title: "Led", icon: _jsx(LightbulbIcon, {}), value: led }), _jsx(ListItemInfo, { title: "Type", icon: _jsx(CategoryIcon, {}), value: type, link: typeLink })] })] })] }) }));
}
