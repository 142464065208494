import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
var MenuContext = React.createContext({});
export function usePopupMenu() {
    return useContext(MenuContext);
}
export function PopupMenu(_a) {
    var children = _a.children, _b = _a.icon, icon = _b === void 0 ? _jsx(MoreVertIcon, {}) : _b;
    var _c = React.useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleCloseMenu = useCallback(function () {
        setAnchorEl(null);
    }, []);
    var value = useMemo(function () { return ({ onCloseMenu: handleCloseMenu }); }, [handleCloseMenu]);
    return (_jsxs(MenuContext.Provider, { value: value, children: [_jsx(IconButton, { "aria-label": "more", onClick: handleClick, children: icon }), _jsx(Menu, { anchorEl: anchorEl, open: open, onClose: handleCloseMenu, children: children })] }));
}
