var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { ALL_TOPICS } from '../topics';
var TOPICS = ALL_TOPICS.map(function (t) { return ({ title: t }); });
var getTopic = function (title) {
    var topic = TOPICS.find(function (topic) { return topic.title === title; });
    if (topic) {
        return topic;
    }
    return { title: title };
};
export function TopicSelector(_a) {
    var title = _a.title, topics = _a.topics, onChange = _a.onChange, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var selectedTopics = topics.map(function (topic) { return getTopic(topic); });
    var handleChange = function (event, newValue) {
        if (onChange) {
            onChange(newValue.map(function (topic) { return topic.title; }));
        }
    };
    return (_jsx(Autocomplete, { multiple: true, options: TOPICS, getOptionLabel: function (option) { return option.title; }, disabled: disabled, value: selectedTopics, onChange: handleChange, ChipProps: { deleteIcon: _jsx(ClearIcon, {}) }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { variant: "standard", label: title, placeholder: "Add topic" }))); } }));
}
